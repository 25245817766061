import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import TheFarm from "../views/TheFarm";
import TheFamily from "../views/TheFamily";
import WeGrow from "../views/WeGrow";
import OurPartners from "../views/OurPartners";
import WhatElse from "../views/WhatElse";

Vue.use(VueRouter);

const routes = [
  {
    path: "/index.html",
    redirect: "/"
  },
  {
    path: "/",
    name: "TheFarm",
    component: TheFarm
  },
  {
    path: "/TheFarm",
    name: "TheFarm",
    component: TheFarm
  },
  {
    path: "/Family",
    name: "TheFamily",
    component: TheFamily
  },
  {
    path: "/WeGrow",
    name: "WeGrow",
    component: WeGrow
  },
  {
    path: "/OurPartners",
    name: "OurPartners",
    component: OurPartners
  },
  {
    path: "/WhatElse",
    name: "WhatElse",
    component: WhatElse
  },
  {
    path: "*",
    name: "root",
    component: TheFarm
  },
  {
    path: "",
    name: "home",
    component: Home
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes
});

export default router;
