<template>
  <v-content>
    <v-slide-x-transition>
      <v-row v-show="show">
        <div class="family-banner"></div>
      </v-row>
    </v-slide-x-transition>

    <v-slide-y-transition>
      <v-row class="content" v-show="show">
        <div style="display:inline-block;">
          <h1 class="ma-0">The Turner Family</h1>
          <hr class="title-line" />
        </div>

        <p class="ma-0">
          Today’s farming life at The Grange continues to have real family
          involvement with John and Guy’s sister Jo running the farm office. The
          next generation of Sons, Daughters, Nieces and Nephews are also now to
          bringing their own diverse set of skills, which are so important for
          the next part of our journey, whether its Tom looking after our IT,
          Trudie with her artistic design skills, Jack with his steel forging
          work or Adam with his love of all things adventure! Our hope is that
          the future of farming at The Grange is one where the opportunity to be
          involved is far less constrained by the traditions of an inherited
          privilege and more one where a much broader range of skills,
          experience and inspiration can carry the farm along its next leg of
          the journey.
        </p>
      </v-row>
    </v-slide-y-transition>
    <v-row align="center" class="p-hare-rule">
      <v-col class="p-rule-col">
        <hr class="p-left-rule" />
      </v-col>
      <v-col class="hare-col">
        <img
          src="../assets/svg/jumping-hare.svg"
          height="40px"
          width="70px"
          draggable="false"
        />
      </v-col>
      <v-col class="p-rule-col">
        <hr class="p-right-rule" />
      </v-col>
    </v-row>
    <div class="d-none d-md-block">
      <v-row class="big-date" justify="center">
        <h2 class="date">1895</h2>
      </v-row>
    </div>
    <v-flex>
      <v-row class="timeline">
        <v-col class="left-img-col" cols="12" sm="12" md="6" lg="6" xl="6">
          <div class="prize-cow" v-show="show"></div>
        </v-col>

        <v-col class="right-col" cols="12" sm="12" md="6" lg="6" xl="6">
          <v-row>
            <!--horizontal line-->
            <div class="d-none d-md-block blocker">
              <v-slide-x-transition>
                <v-col class="line" v-show="show">
                  <v-row class="top-pad"></v-row>
                  <v-row class="h-line"></v-row>
                </v-col>
              </v-slide-x-transition>
            </div>
            <v-col class="left-margin">
              <v-slide-x-reverse-transition>
                <div class="right-container" v-show="show">
                  <div class="d-none d-md-block blocker">
                    <img
                      src="../assets/svg/bright-blue-rec.svg"
                      class="right-triangle"
                      v-show="show"
                    />
                  </div>
                  <div class="info-card-right">
                    <h3 class="date">1895</h3>
                    <h2 class="name">John & Caroline</h2>
                    <p class="card-text">
                      John’s family farmed at Skellingthorpe, near Lincoln, but
                      as one of 13 children, he needed to take his farming
                      ambitions away from home and under the watchful eye of his
                      uncle Ruben Flint, came to work on the Holywell Estate. He
                      soon met a local girl, Caroline, and had ambitions to be
                      married, but to do so, he would need better prospects than
                      his present work offered. The Grange was at the time part
                      of the Holywell Estate and by chance a vacant tenancy
                      arose. The diaries kept by John, in beautiful copperplate
                      writing, detail his endeavours, many of which were
                      recognised through the prizes he was clearly very proud to
                      receive.
                    </p>
                  </div>
                </div>
              </v-slide-x-reverse-transition>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-flex>

    <!--mobile-->
    <div class="d-md-none">
      <v-row class="timeline">
        <v-col class="right-col" cols="12" sm="12" md="6" lg="6" xl="6">
          <div class="jack-and-margaret"></div>
        </v-col>
        <v-col class="left-col" cols="12" sm="12" md="6" lg="6" xl="6">
          <v-row>
            <v-col class="no-padding">
              <v-slide-x-transition>
                <div class="right-container" v-show="show">
                  <div class="d-none d-md-block blocker">
                    <img
                      src="../assets/svg/pale-blue-left.svg"
                      class="left-triangle"
                      v-show="show"
                    />
                  </div>
                  <div class="info-card-left">
                    <h3 class="date">1932</h3>
                    <h2 class="name">John "Jack" & Margaret</h2>
                    <p class="card-text">
                      “Jack” began his professional career as a teacher before
                      going on to work for the British Foreign Office. He worked
                      in Khartoum at a time when Sudan was under a shared
                      British and Egyptian administration, overseeing the use of
                      precious water supplies to irrigate crops. A love of
                      tropical agriculture and warm climates is something that
                      would stay with Jack, including a number of years farming
                      in Portugal during his retirement. However, the untimely
                      failing of his father’s eyesight meant that his time in
                      the Sudan was cut short and he returned home to help with
                      what was now the family farm. The 1930s and immediate
                      post-war period were challenging times for British
                      farming, but Jack’s resourceful use of machinery and
                      materials helped steer the farm through some of its most
                      difficult times.
                    </p>
                  </div>
                </div>
              </v-slide-x-transition>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>

    <!--desktop-->
    <div class="d-none d-md-block blocker">
      <v-row class="timeline">
        <v-col class="left-col" cols="12" sm="12" md="6" lg="6" xl="6">
          <v-row>
            <v-col class="no-padding">
              <v-slide-x-transition>
                <div class="right-container" v-show="show">
                  <div class="d-none d-md-block blocker">
                    <img
                      src="../assets/svg/green-rec.svg"
                      class="left-triangle"
                      v-show="show"
                    />
                  </div>
                  <div class="info-card-left">
                    <h3 class="date">1932</h3>
                    <h2 class="name">John "Jack" & Margaret</h2>
                    <p class="card-text">
                      “Jack” began his professional career as a teacher before
                      going on to work for the British Foreign Office. He worked
                      in Khartoum at a time when Sudan was under a shared
                      British and Egyptian administration, overseeing the use of
                      precious water supplies to irrigate crops. A love of
                      tropical agriculture and warm climates is something that
                      would stay with Jack, including a number of years farming
                      in Portugal during his retirement. However, the untimely
                      failing of his father’s eyesight meant that his time in
                      the Sudan was cut short and he returned home to help with
                      what was now the family farm. The 1930s and immediate
                      post-war period were challenging times for British
                      farming, but Jack’s resourceful use of machinery and
                      materials helped steer the farm through some of its most
                      difficult times.
                    </p>
                  </div>
                </div>
              </v-slide-x-transition>
            </v-col>
            <!--horizontal line-->
            <div class="d-none d-md-block blocker">
              <v-slide-x-reverse-transition>
                <v-col class="left-line" v-show="show">
                  <v-row class="top-pad"></v-row>
                  <v-row class="h-line"></v-row>
                </v-col>
              </v-slide-x-reverse-transition>
            </div>
          </v-row>
        </v-col>
        <v-col class="right-col" cols="12" sm="12" md="6" lg="6" xl="6">
          <div class="jack-and-margaret"></div>
        </v-col>
      </v-row>
    </div>

    <v-row class="timeline">
      <v-col class="left-img-col" cols="12" sm="12" md="6" lg="6" xl="6">
        <div class="tony-and-judy"></div>
      </v-col>
      <v-col class="right-col" cols="12" sm="12" md="6" lg="6" xl="6">
        <v-row>
          <!--horizontal line-->
          <div class="d-none d-md-block blocker">
            <v-slide-x-transition>
              <v-col class="line" v-show="show">
                <v-row class="top-pad"></v-row>
                <v-row class="h-line"></v-row>
              </v-col>
            </v-slide-x-transition>
          </div>
          <v-col class="left-margin">
            <v-slide-x-reverse-transition>
              <div class="right-container" v-show="show">
                <div class="d-none d-md-block blocker">
                  <img
                    src="../assets/svg/dark-triangle.svg"
                    class="right-triangle"
                    v-show="show"
                  />
                </div>
                <div class="info-card-right">
                  <h3 class="date">1961</h3>
                  <h2 class="name">Anthony & Judy</h2>
                  <p class="card-text">
                    Following time spent in the RAF Regiment 'Rock Apes' as his
                    National Service, Anthony returned to Little Bytham, taking
                    on Sheepwash Farm and with just 5 cows, began the dairy herd
                    that was to become the main focus for the family farm for
                    the following 45 years. Judy managed to juggle work as a
                    supply teacher with the daily milking chores while at the
                    same time raising their 4 children. Although retired,
                    Anthony and Judy still live in the village, next door to
                    Sheepwash Farm, and follow the farm’s changing patterns with
                    the same keen interest.
                  </p>
                </div>
              </div>
            </v-slide-x-reverse-transition>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!--mobile-->
    <div class="d-md-none">
      <v-row class="timeline">
        <v-col class="right-col" cols="12" sm="12" md="6" lg="6" xl="6">
          <div class="john-and-guy"></div>
        </v-col>
        <v-col class="left-col" cols="12" sm="12" md="6" lg="6" xl="6">
          <v-row>
            <v-col class="no-padding">
              <v-slide-x-transition>
                <div class="right-container" v-show="show">
                  <div class="d-none d-md-block blocker">
                    <img
                      src="../assets/svg/pale-blue-left.svg"
                      class="left-triangle"
                      v-show="show"
                    />
                  </div>
                  <div class="info-card-left">
                    <h3 class="date">1995</h3>
                    <h2 class="name">John & Guy</h2>
                    <p class="card-text">
                      Brothers John and Guy have helped to steer the farm’s
                      fortunes over the past 25 years – one that has taken an
                      alternative path to the general trend yet one which has
                      enabled us to stay true to the principles of good farming
                      husbandry that the previous generations at The Grange
                      strove to follow. The farm converted to organic principles
                      in 1998, laying the foundations for so much of what the
                      farm is able to do today. An ambitious scheme of tree and
                      hedge planting was undertaken shortly afterwards, and a
                      more formal 7-year crop rotation was introduced, which we
                      still follow today.
                    </p>
                  </div>
                </div>
              </v-slide-x-transition>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>

    <!--desktop-->
    <div class="d-none d-md-block blocker">
      <v-row class="timeline">
        <v-col class="left-col" cols="12" sm="12" md="6" lg="6" xl="6">
          <v-row>
            <v-col class="no-padding">
              <v-slide-x-transition>
                <div class="right-container" v-show="show">
                  <div class="d-none d-md-block blocker">
                    <img
                      src="../assets/svg/pale-blue-left.svg"
                      class="left-triangle"
                      v-show="show"
                    />
                  </div>
                  <div class="info-card-left">
                    <h3 class="date">1995</h3>
                    <h2 class="name">John & Guy</h2>
                    <p class="card-text">
                      Brothers John and Guy have helped to steer the farm’s
                      fortunes over the past 25 years – one that has taken an
                      alternative path to the general trend yet one which has
                      enabled us to stay true to the principles of good farming
                      husbandry that the previous generations at The Grange
                      strove to follow. The farm converted to organic principles
                      in 1998, laying the foundations for so much of what the
                      farm is able to do today. An ambitious scheme of tree and
                      hedge planting was undertaken shortly afterwards, and a
                      more formal 7-year crop rotation was introduced, which we
                      still follow today.
                    </p>
                  </div>
                </div>
              </v-slide-x-transition>
            </v-col>
            <!--horizontal line-->
            <div class="d-none d-md-block blocker">
              <v-slide-x-reverse-transition>
                <v-col class="left-line" v-show="show">
                  <v-row class="top-pad"></v-row>
                  <v-row class="h-line"></v-row>
                </v-col>
              </v-slide-x-reverse-transition>
            </div>
          </v-row>
        </v-col>
        <v-col class="right-col" cols="12" sm="12" md="6" lg="6" xl="6">
          <div class="john-and-guy"></div>
        </v-col>
      </v-row>
    </div>
    <div class="d-none d-md-block">
      <v-row class="big-date" justify="center">
        <h2 class="date">2020</h2>
      </v-row>
    </div>
  </v-content>
</template>

<script>
export default {
  name: "TheFamily",
  components: {},
  metaInfo() {
    return {
      title: "Turners of Bytham | Family",
      meta: [
        {
          name: "description",
          content:
            "Explore the heritage of Turners of Bytham, from its inception in 1895 by John & Caroline Turner, to current farmers John & Guy Turner."
        },
        {
          property: "og:title",
          content: "Turners of Bytham | Family"
        },
        { property: "og:site_name", content: "Turners of Bytham" },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" }
      ]
    };
  },
  mounted() {
    this.show = true;
  },
  data() {
    return {
      show: false
    };
  }
};
</script>

<style lang="scss" scoped>
$timeline-color: rgba(190, 157, 103, 1);

.p-rule-col {
  max-height: 2px;
  padding-left: 20px;
  padding-right: 20px;
}

hr.p-left-rule {
  margin-left: 25% !important;
  width: 75%;
  padding: 0;
  margin: 0;
}

hr.p-right-rule {
  margin-right: 25% !important;
  width: 75%;
  padding: 0;
  margin: 0;
}

.p-hare-rule {
  margin-top: 5%;
  margin-bottom: 5%;
}

h1 {
  padding-bottom: 0;
}

hr.title-line {
  margin: 0;
  width: 100%;
  margin-top: -5%;
  margin-bottom: 10%;
  margin-left: 2.5px;
  border: 2px solid $underline-color;
  border-radius: 1px;
}

h3.date {
  color: $timeline-color;
  font-family: Monterchi-Book, serif;
  margin-bottom: -0.5em;
}

p.card-text {
  font-size: 18px;
}

.right-triangle {
  z-index: 1;
  position: absolute;
  bottom: -20px;
  left: -20px;
  max-width: 50%;
  max-height: 50%;
}

.family-banner {
  background-image: url("../assets/drone-shot.jpg");
  background-size: cover;
  min-width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  display: flex;
  justify-content: center;
}

h1 {
}

.left-triangle {
  z-index: 1;
  position: absolute;
  bottom: -20px;
  right: -20px;
  max-width: 50%;
  max-height: 50%;
}

.right-container {
  position: relative;
  transition: transform 150ms ease-in-out;
  max-width: 100%;
  max-height: 100%;
  padding-top: 0;
  margin-bottom: 25px;
}

.blocker {
  z-index: 1;
}

.no-padding {
  padding: 0;
  max-width: 95%;
}

.prize-cow {
  background-image: url("../assets/prize-cow.jpg");
  background-size: cover;
  height: $img-height-l;
  width: 95%;
}

.jack-and-margaret {
  background-image: url("../assets/jack-and-margaret.png");
  background-size: cover;
  height: $img-height-l;
  width: 95%;
  margin-left: 5%;
}

.tony-and-judy {
  background-image: url("../assets/tony-and-judy.png");
  background-size: cover;
  height: $img-height-l;
  width: 95%;
}

.john-and-guy {
  background-image: url("../assets/john-and-guy.png");
  background-size: cover;
  height: $img-height-l;
  width: 95%;
  margin-left: 5%;
}

.content {
  padding: 5%;
  padding-bottom: 0;
}

.container {
  position: relative;
  display: inline-block;
  transition: transform 150ms ease-in-out;
  max-width: 100%;
  max-height: 100%;
  padding-top: 0;
  margin-bottom: 25px;
  z-index: 1;
}

.info-card-right {
  padding: 3em;
  margin: 5%;
  margin-left: 0;
  background: #fdfdfd;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 4;
  position: relative;
}

.info-card-left {
  padding: 3em;
  margin: 5%;
  margin-right: 0;
  background: #fdfdfd;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 4;
  position: relative;
}

.left-info-card {
  padding: 3em;
  margin: 5%;
  margin-right: 0;
  background: #fdfdfd;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 4;
  position: relative;
}

.left-margin {
  margin-left: 5%;
}

.timeline {
}

.line {
  width: 3.5%;
  background: transparent;
  position: absolute;
  z-index: 1;
}

.left-line {
  height: inherit;
  width: 2.7%;
  z-index: 1;
  position: absolute;
}

.h-line {
  border-top: 10px solid $timeline-color;
  z-index: 1;
}

.top-pad {
  height: 150px;
  z-index: 1;
}

.left-col {
  text-align: left;
  border-right: 5px solid $timeline-color;
  width: 50%;
  padding-top: 5%;
}

.left-img-col {
  text-align: right;
  border-right: 5px solid $timeline-color;
  width: 50%;
  padding-top: 5%;
  padding-right: 0;
}

.right-col {
  text-align: left;
  width: 50%;
  border-left: 5px solid $timeline-color;
  padding-top: 5%;
}

.right-img-col {
  text-align: right;
  width: 50%;
  border-left: 5px solid $timeline-color;
  padding-top: 5%;
  padding-left: 0;
}

.big-date {
  h2 {
    color: $timeline-color;
  }
}

@media screen and (max-width: 375px) {
  .family-banner {
    height: 200px;
  }

  h1 {
    font-size: $title-size-xs !important;
    margin: 0% 5% 0% 5% !important;
    padding: 0% 0% 3% 0% !important;
  }

  p {
    font-size: $paragraph-size-xs;
    margin: 0% 5% 10% 5% !important;
    text-align: justify;
  }

  h2.name {
    font-size: 22px !important;
    margin: 0% 0% 0% 5% !important;
  }

  h3.date {
    font-size: 16px;
    margin: 0% 0% 0% 5% !important;
  }

  .card-text {
    font-size: 12px !important;
  }

  .info-card-left {
    padding: 2em;
    background: #ffffff;
  }

  .info-card-right {
    padding: 2em;
    background: #ffffff;
  }

  .john-and-guy {
    height: 300px;
    width: 100%;
    margin: 0;
  }

  .tony-and-judy {
    height: 300px;
    width: 100%;
    margin: 0;
  }

  .jack-and-margaret {
    height: 300px;
    width: 100%;
    margin: 0;
  }

  .prize-cow {
    height: 300px;
    width: 100%;
    margin: 0;
  }
  .left-margin {
    margin: 0;
  }

  .left-col {
    padding: 0;
  }

  .left-img-col {
    padding-bottom: 0;
  }

  .right-col {
    padding: 0;
  }

  .right-container {
    margin: 0;
  }

  .info-card-left {
    margin: 0 !important;
    padding: 7.5%;
    box-shadow: none;
  }
  .info-card-right {
    margin: 0 !important;
    padding: 7.5%;
    box-shadow: none;
  }

  .no-padding {
    max-width: 100%;
  }

  .left-margin {
    padding: 0;
  }

  .left-img-col {
    padding: 0;
  }
}

@media screen and (min-width: 376px) and (max-width: 600px) {
  .family-banner {
    height: 200px;
  }

  h1 {
    font-size: $title-size-s !important;
    padding: 0% 0% 3% 0% !important;
  }

  p {
    font-size: $paragraph-size-xs;
    text-align: justify;
  }

  h2.name {
    font-size: 22px !important;
  }

  h3.date {
    font-size: 16px;
  }

  .card-text {
    font-size: 12px !important;
  }

  .info-card-left {
    padding: 2em;
    background: #ffffff;
  }

  .info-card-right {
    padding: 2em;
    background: #ffffff;
  }

  .john-and-guy {
    height: 400px;
    width: 100%;
    margin: 0;
  }

  .tony-and-judy {
    height: 400px;
    width: 100%;
    margin: 0;
  }

  .jack-and-margaret {
    height: 400px;
    width: 100%;
    margin: 0;
  }

  .prize-cow {
    height: 400px;
    width: 100%;
    margin: 0;
  }
  .left-margin {
    margin: 0;
  }

  .left-col {
    padding: 0;
  }

  .left-img-col {
    padding-bottom: 0;
  }

  .right-col {
    padding: 0;
  }

  .right-container {
    margin: 0;
  }

  .info-card-left {
    margin: 0 !important;
    padding: 7.5%;
    box-shadow: none;
  }
  .info-card-right {
    margin: 0 !important;
    padding: 7.5%;
    box-shadow: none;
  }

  .no-padding {
    max-width: 100%;
  }

  .left-margin {
    padding: 0;
  }

  .left-img-col {
    padding: 0;
  }
}

@media screen and (min-width: 601px) and (max-width: 959px) {
  .family-banner {
    height: 300px;
  }

  h1 {
    font-size: $title-size-s !important;
    padding: 0% 0% 3% 0% !important;
  }

  p {
    font-size: $paragraph-size-s;
    text-align: justify;
  }

  h2.name {
    font-size: 22px !important;
  }

  h3.date {
    font-size: 16px;
  }

  .card-text {
    font-size: 12px !important;
  }

  .info-card-left {
    padding: 2em;
    background: #ffffff;
  }

  .info-card-right {
    padding: 2em;
    background: #ffffff;
  }

  .john-and-guy {
    height: 400px;
    width: 100%;
    margin: 0;
  }

  .tony-and-judy {
    height: 400px;
    width: 100%;
    margin: 0;
  }

  .jack-and-margaret {
    height: 400px;
    width: 100%;
    margin: 0;
  }

  .prize-cow {
    height: 400px;
    width: 100%;
    margin: 0;
  }
  .left-margin {
    margin: 0;
  }

  .left-col {
    padding: 0;
  }

  .left-img-col {
    padding-bottom: 0;
    border-right: none;
  }

  .right-col {
    padding: 0;
  }

  .right-container {
    margin: 0;
  }

  .info-card-left {
    margin: 0 !important;
    padding: 7.5%;
    box-shadow: none;
  }
  .info-card-right {
    margin: 0 !important;
    padding: 7.5%;
    box-shadow: none;
  }

  .no-padding {
    max-width: 100%;
  }

  .left-margin {
    padding: 0;
  }

  .left-img-col {
    padding: 0;
  }
}

@media screen and (min-width: 960px) and (max-width: 1263px) {
  h2.name {
    font-size: 26px !important;
  }

  h3.date {
    font-size: 16px;
  }

  .card-text {
    font-size: 14px !important;
  }

  .john-and-guy {
    height: 400px;
  }

  .tony-and-judy {
    height: 400px;
  }

  .jack-and-margaret {
    height: 400px;
  }

  .prize-cow {
    height: 400px;
  }
}
</style>
