<template>
  <v-app>
    <v-app-bar fixed app light color="white" class="navbar">
      <v-toolbar-title>
        <v-img
          src="./assets/logo.png"
          width="60px"
          height="50px"
          class="logo"
          @click="$router.push('thefarm')"
        ></v-img>
      </v-toolbar-title>
      <v-col class="pa-0">
        <h1 class="nav-title" @click="$router.push('thefarm')">Turners of Bytham</h1>
      </v-col>

      <v-toolbar-items class="hidden-sm-and-down">
        <v-btn
          v-for="item in nav"
          :key="item.icon"
          :to="item.route"
          color="white"
          class="button"
          light
        >
          {{ item.text }}
        </v-btn>
      </v-toolbar-items>
      <div class="d-md-none">
        <v-menu transition="slide-y-transition" bottom>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" class="burger">fas fa-bars</v-icon>
          </template>
          <v-list>
            <v-list-item @click="$router.push('thefarm')">
              <v-list-item-title>
                The Farm
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="$router.push('/family')">
              <v-list-item-title>
                Our Family
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="$router.push('wegrow')">
              <v-list-item-title>
                We Grow
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="$router.push('ourpartners')">
              <v-list-item-title>
                Partners
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="$router.push('whatelse')">
              <v-list-item-title>
                What Else
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>

    <router-view></router-view>

    <social-feed></social-feed>
    <v-footer color="white">
      <v-row>
        <v-col
          class="footer-col pa-0"
          cols="12"
          sm="5"
          md="5"
          lg="5"
          xl="5"
          align="right"
          order-xl="last"
          order-lg="last"
          order-md="last"
          order-sm="last"
        >
          <p class="ma-0">
            <a href="https://lb5k.org" class="footer-link" target="_blank">LB5K.ORG</a>
          </p>
          <p class="ma-0">
            <a href="mailto:office@turnersofbytham.co.uk" class="footer-link"
              >OFFICE@TURNERSOFBYTHAM.CO.UK</a
            >
          </p>
        </v-col>
        <v-col
          class="hidden-xs-only pa-0"
          sm="2"
          md="2"
          lg="2"
          xl="2"
          align="center"
        >
          <svg
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            width="40.000000px"
            height="60.000000px"
            viewBox="0 0 39.000000 100.000000"
            preserveAspectRatio="xMidYMid meet"
            class="sitting"
          >
            <g
              transform="translate(0.000000,100.000000) scale(0.100000,-0.100000)"
              fill="#353334"
              stroke="none"
            >
              <path
                d="M203 978 c-9 -11 -35 -78 -50 -128 -2 -9 -16 7 -34 40 -16 30 -37 62
-45 70 -14 13 -17 13 -30 -4 -22 -30 -17 -113 10 -171 15 -33 20 -57 15 -70
-16 -41 -10 -99 14 -129 23 -28 23 -29 5 -80 -9 -28 -17 -74 -18 -103 0 -36
-8 -66 -26 -100 -26 -50 -26 -46 -10 -176 7 -48 32 -75 88 -92 28 -8 68 -15
88 -15 47 0 124 39 128 65 1 11 9 49 17 85 14 61 14 72 -5 158 -20 98 -45 163
-81 213 -18 25 -19 34 -10 61 16 44 14 79 -7 119 -15 30 -16 40 -5 65 17 41
16 116 -3 164 -17 42 -25 48 -41 28z"
              />
            </g>
          </svg>
        </v-col>

        <v-col
          class="footer-col pa-0"
          cols="12"
          sm="5"
          md="5"
          lg="5"
          xl="5"
          order-xl="first"
          order-lg="first"
          order-md="first"
          order-sm="first"
        >
          <p class="ma-0 footer-text">© PM Images and Farming Photography</p>
          <p class="ma-0 footer-text">
            Design & Artwork by Trudy Turner Illustrations
          </p>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import SocialFeed from "./components/SocialFeed";
export default {
  name: "App",
  components: { SocialFeed },
  mounted() {
    this.show = true;
  },
  data() {
    return {
      show: false,
      dialog: false,
      nav: [
        {
          text: "The Farm",
          route: "/thefarm",
          active: false
        },
        {
          text: "Our Family",
          route: "/family",
          active: false
        },
        {
          text: "We Grow",
          route: "/WeGrow",
          active: false
        },
        {
          text: "Partners",
          route: "/OurPartners",
          active: false
        },
        {
          text: "What Else",
          route: "/WhatElse",
          active: false
        }
      ]
    };
  }
};
</script>

<style lang="scss">
@font-face {
  font-family: "Monterchi-Book";
  src: url("./assets/fonts/webFonts/Monterchi-Book/font.woff2") format("woff2"),
    url("./assets/fonts/webFonts/Monterchi-Book/font.woff") format("woff");
}

.centered {
  text-align: center;
  width: 20% !important;
}

.v-window__next {
  background: none !important;
  right: -15px !important;
}

.burger {
  color: rgba(53, 51, 52, 0.9) !important;
}

a.instagram-photo {
  width: calc(100%) !important;
  display: block;
}

.photo-container {
  width: calc(25%) !important;
  display: block;
  height: auto !important;
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: $underline-color;
}

.photo-container:hover .overlay {
  opacity: 1;
}

.v-content {
  margin-bottom: 0 !important;
}

.overlaytext {
  color: white;
  font-size: 18px;
  position: absolute;
  top: 50%;
  left: 50%;
  width: calc(75%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.instagram-photos img {
  background-size: cover;
  height: inherit;
  width: inherit;
  min-width: 100%;
  min-height: 100%;
}

.credits {
  position: absolute;
  bottom: 15%;
}

.v-window__prev {
  background: none !important;
  left: -15px !important;
}

.v-carousel__controls {
  background: none !important;
}

.v-btn--icon {
  color: #af9a6a !important;
}

.v-btn {
  box-shadow: none !important;
  color: #555555;
}

.slick-dots li.slick-active button::before {
  color: #8c5f2d !important;
}

.v-dialog {
  max-width: 1400px;
}

.v-card--link {
  margin-bottom: 30px !important;
}

.sitting {
  display: block;
  margin: auto;
}

.hidden-xs-only {
  width: 20%;
  display: inherit;
}

.navbar {
  z-index: 11;
}

.nav-title {
  cursor: pointer;
  font-family: Monterchi-Book;
  font-size: 32px !important;
  line-height: 1;
  color: #353334;
  padding-left: 5px;
  padding-top: 10px;
}

.mobile-title {
  font-family: Monterchi-Book;
  font-size: 22px;
  font-weight: 600;
  color: #353334;
}

.v-toolbar__content {
  padding-right: 0 !important;
}

.v-footer {
  padding-right: 25px !important;
  padding-left: 25px !important;
}

.burger {
  margin-right: 10px;
  margin-left: 10px;
}

.logo {
  cursor: pointer;
  top: 15% !important;
  height: 45px !important;
  width: 50px !important;
}

.left-size {
  width: 40% !important;
  display: inline-table;
  left: 0;
  position: absolute;
  bottom: 0;
}

.ftr-right {
  text-align: right;
  display: inline-table;
  right: 0;
  position: absolute;
  bottom: 0;
}

p.footer {
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
  margin-bottom: 0.25em !important;
}

a.footer-link {
  line-height: 0.5;
  font-size: 14px;
  font-weight: 400;
}

p.footer-text {
  line-height: 2;
  font-size: 11px;
  font-weight: 400;
}

a {
  color: rgba(53, 51, 52, 0.85) !important;
  text-decoration: none;
}
@media screen and (max-width: 375px) {
  .overlaytext {
    font-size: $paragraph-size-xxs !important;
    height: calc(90%);
  }

  p.footer {
    font-size: 12px;
  }

  .instagram-photo {
    background-color: $underline-color;
  }

  p.footer-bottom {
    font-size: 11px;
  }

  a.footer-link {
    font-size: 12px;
  }

  p.footer {
    font-size: 11px;
    line-height: 1.5;
    font-weight: 400;
    margin-bottom: 0.5em !important;
  }

  p.footer-bottom {
    font-size: 11px;
    line-height: 1;
    margin-bottom: 0px !important;
    font-weight: 400;
  }

  p.footer-link {
    line-height: 0.5;
    font-size: 11px;
    font-weight: 400;
  }

  .footer-col {
    text-align: center;
    margin: 0.5em;
  }

  .nav-title {
    font-size: 28px !important;
    text-align: center;
  }
}

@media screen and (max-width: 420px) {
  .left-size {
    max-width: 50% !important;
  }
}

@media screen and (min-width: 376px) and (max-width: 600px) {
  .v-btn__content {
    font-size: 12px !important;
  }

  .overlaytext {
    font-size: $paragraph-size-xxs !important;
    height: calc(90%);
  }

  .nav-title {
    text-align: center;
    font-size: 32px;
  }

  .footer-col {
    text-align: center;
    margin: 0.5em;
  }
}

@media screen and (min-width: 601px) and (max-width: 959px) {
  .overlaytext {
    font-size: $paragraph-size-xs !important;
  }

  .nav-title {
    font-size: 32px !important;
  }
}

@media screen and (min-width: 960px) and (max-width: 1263px) {
  .overlaytext {
    font-size: $paragraph-size-m !important;
  }
}
</style>
