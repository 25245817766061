<template>
  <v-content> </v-content>
</template>

<script>
export default {
  name: "Home",
  components: {},
  mounted() {
    this.show = true;
  },
  data() {
    return {
      show: false
    };
  }
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Lora&display=swap");

h1 {
  font-weight: 100;
  font-size: $title-size-l;
  color: #555555;
}

h1.grange {
  z-index: 2;
  line-height: 1;
}

h2 {
  font-family: "Lora", serif;
  font-weight: 100;
  font-size: $subtitle-size-l;
}

h2.welcome {
  color: rgba(190, 157, 103, 1);
  padding-left: 3px;

  font-size: $flair-size-l;
}

h2.passion {
  color: #555555;
  padding-top: 1em;
  padding-left: 1.3em;
  font-size: $subtitle-size-l;
}

h3 {
  font-family: "Open Sans", sans-serif;
  color: #555555;
  font-weight: 500;
  font-size: $header-size-l;
}

p {
  font-family: "Open Sans", sans-serif;
  color: #696969;
  font-size: $paragraph-size-l;
}

p.welcome-p {
}

p.description {
  font-size: $paragraph-size-s;
  color: #555555;
}

.john {
  background-image: url("../assets/welcome.png");
  height: $img-height-l;
  background-size: cover;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  z-index: 2;
  position: relative;
}

.blocker {
  z-index: 1;
}

.john-triangle {
  z-index: 1;
  position: absolute;
  bottom: -10px;
  left: -10px;
  max-width: 50%;
  max-height: 50%;
}

.container {
  position: relative;
  display: inline-block;
  transition: transform 150ms ease-in-out;
  max-width: 100%;
  max-height: 100%;
  padding-top: 0;
  margin-bottom: 0;
  padding-left: 0;
}

.title-container {
  position: relative;
  display: inline-block;
  transition: transform 150ms ease-in-out;
  min-width: 100%;
  padding-top: 0;

}




.icon-row {
  padding-left: 2em;
  padding-right: 5em;
}

.header-img {
}

.desc {
  margin-left: -50%;
}

.passion-container {
  background-color: #fcf6e9;
}

.passion-row {
  background-color: #fcf6e9;
}

.passion-col {
  padding: 0px;
}

.white-spacer {
  background-color: #ffffff;
  height: 100px;
}

.white-col-spacer {
  background-color: #ffffff;
  height: 75px;
}

.passion-spacer {
  background-color: #fcf6e9;
  height: 75px;
}

.underline {
  z-index: 0;
  position: absolute;
  bottom: 0;
  left: 0;
}

@media screen and (max-width: 375px) {
  p {
    font-size: $paragraph-size-xs;
  }

  p.description {
    font-size: $paragraph-size-xxs;
  }

  p.welcome-p {
  }

  h1 {
    font-size: $title-size-xs;
  }

  h2.welcome {
    font-size: $flair-size-xs;
    padding: 0px;
  }

  h2.passion {
    padding-left: 10%;
    font-size: $subtitle-size-xs;
  }

  h3 {
    font-size: $header-size-xs;
  }

  .title-container {
    margin-bottom: 16px;
  }

  .welcome {
    padding: 5%;
  }


  .icon-row {
    padding-right: 10%;
    padding-left: 7.5%;
  }

  .container {
    padding-bottom: 0px !important;
    padding-right: 0px !important;
    margin-bottom: -10px !important;
  }

  .john {
    height: $img-height-xs !important;
  }

  .group {
    height: $img-height-xs !important;
  }
}
@media screen and (min-width: 376px) and (max-width: 600px) {
  h1.grange {
    font-size: $title-size-s;
  }

  p.welcome-p {
    margin-bottom: 5% !important;
    font-size: $paragraph-size-s;
  }

  p.description {
    font-size: $paragraph-size-s;
  }

  .welcome {
    padding-top: 5%;
    padding-bottom: 5%;
  }

  .home-banner {
    height: 200px;
  }

  h2.welcome {
    font-size: $flair-size-s;
    padding-top: 0%;
  }

  h2.passion {
    padding-left: 10%;
    font-size: $header-size-s;
  }

  h3 {
    font-size: $header-size-xs;
  }

  .icon-row {
    padding-right: 10%;
    padding-left: 7.5%;
  }

  .container {
    padding-bottom: 0px !important;
    padding-right: 0px !important;
    margin-bottom: -10px !important;
  }

  .john {
    height: $img-height-s !important;
  }
  .group {
    height: $img-height-s !important;
  }
}

@media screen and (min-width: 601px) and (max-width: 959px) {
  .h1 {
    font-size: $title-size-m;
  }

  .p {
    font-size: $paragraph-size-s !important;
  }

  p.welcome-p {
    margin-bottom: 5% !important;
    font-size: $paragraph-size-s;
  }

  .welcome {
    padding-top: 5%;
    padding-bottom: 5%;
  }

  h2.welcome {
    padding: 0px;
  }

  .container {
    padding-bottom: 0px;
    padding-right: 0px;
    margin-bottom: -10px !important;
  }

  .home-banner {
    height: 300px;
  }

  p.welcome-p {
  }

  .john {
    height: $img-height-m !important;
  }

  .group {
    height: $img-height-m !important;
  }
}

@media screen and (min-width: 960px) and (max-width: 1263px) {
  p.welcome-p {
    font-size: 20px;
  }
}
</style>
