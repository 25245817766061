<template>
  <v-content>
    <v-slide-x-transition>
      <v-row v-show="show">
        <div class="we-banner"></div>
      </v-row>
    </v-slide-x-transition>
    <v-slide-y-transition>
      <v-row class="title-row" v-show="show">
        <div style="display: inline-block">
          <h1>Open Farm Everyday</h1>
          <hr class="title-line" />
        </div>
      </v-row>
    </v-slide-y-transition>
    <v-slide-y-transition>
      <v-row class="text" v-show="show">
        <p>
          The farm has an extensive range of permissive footpaths, which offer
          circular walks ranging from a short stroll to a full blown hike. It’s
          a privilege to live on such a lovely farm and its one that we like to
          share as widely as possible.
        </p>
        <p>
          For our part, all we ask is for a few important points to be borne in
          mind:
        </p>
        <p>
          For dog walkers, please carry a poop bag and use it. Apart from
          keeping the paths in good order for other users, there are potentially
          serious problems that the cattle can succumb to if dog owners choose
          not to carry their responsibilities and instead happen to leave them
          in the fields.
        </p>
        <p>
          We invest a lot of time and effort in encouraging our brown hares and
          ground nesting birds, and all that work can so easily be undone if
          dogs are not kept under close control.
        </p>
        <p>
          Similarly, there are cows with young calves on the farm and like most
          mums, they can be very protective if they see a wolf eying up their
          little daisy for lunch, even if the “wolf” is the softest Labrador in
          the world, those same basic instincts still kick in. We spend a lot of
          time talking to our cows – some say more than we should – and they are
          fairly chilled creatures as a result, so we hope everyone does their
          bit to keep theirs a happy, safe place to raise their kids.
        </p>
      </v-row>
    </v-slide-y-transition>

    <v-row class="section">
      <v-col class="map-container" cols="12" sm="12" md="12" lg="12" xl="12">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2968.7386671356335!2d-0.5025856838670704!3d52.74581487985755!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487826d614deb269%3A0x4514a491e65c67b7!2sThe%20Grange%2C%20Little%20Bytham%2C%20Grantham%20NG33%204QS!5e1!3m2!1sen!2suk!4v1585824813871!5m2!1sen!2suk"
          width="100%"
          height="650"
          frameborder="0"
          style="border:0;"
          allowfullscreen
          aria-hidden="false"
          tabindex="0"
        ></iframe>
      </v-col>
    </v-row>

    <v-row class="run-row">
      <v-col class="wild-col" cols="12" sm="12" md="6" lg="6" xl="6">
        <div style="display: inline-block">
          <h1>Wildlife</h1>
          <hr class="wildlife-line" />
        </div>
        <p>
          We are fortunate to have such a wide range of habitats to support
          wildlife on the farm. Streams, ponds, a former clay pit, a disused
          railway line and old limestone workings are all managed in ways to
          make the most of what they can offer for insects, birds and mammals.
        </p>

        <p>
          Our Organic system of farming means that the food networks necessary
          to support wildlife are interwoven with the areas we use for producing
          crops and growing grass. Within the cereal fields that grow the wheats
          and oats, you will also find plants that would be easy to dismiss as
          simply “weeds” but they are as important for feeding the wildlife as
          the crops are for feeding us.
        </p>
        <p>
          Every aspect of what we do at The Grange is a partnership, and
          managing our wildlife is no exception. We are extremely fortunate to
          have our very own dedicated “Grange Rangers” in the form of Pete and
          Jackie Murray who make a huge contribution to what we do; from an
          annual spring clean of the nesting boxes to monitoring our butterfly
          and day moth populations.
        </p>
      </v-col>
      <v-col class="pa-0" cols="12" sm="12" md="6" lg="6" xl="6">
        <v-slide-x-reverse-transition>
          <div class="wild-container" v-show="show">
            <div class="d-none d-md-block blocker">
              <img
                src="../assets/svg/bright-blue-rec.svg"
                class="triangle"
                v-show="show"
              />
            </div>
            <div class="ramblers"></div>
          </div>
        </v-slide-x-reverse-transition>
      </v-col>
    </v-row>

    <!--desktop-->
    <div class="d-none d-md-block">
      <v-row class="run-row">
        <v-col class="pa-0" cols="12" sm="12" md="6" lg="6" xl="6">
          <v-slide-x-reverse-transition>
            <div class="run-container" v-show="show">
              <div class="d-none d-md-block blocker">
                <img
                  src="../assets/svg/green-rec.svg"
                  class="run-triangle"
                  v-show="show"
                />
              </div>
              <div class="lb5k"></div>
            </div>
          </v-slide-x-reverse-transition>
        </v-col>
        <v-col class="wild-col" cols="12" sm="12" md="6" lg="6" xl="6">
          <div style="display: inline-block">
            <h1>LB5K Farm Run</h1>
            <hr class="run-line" />
          </div>
          <p>
            Once a year, we give the permissive footpaths an extra special
            spruce up to welcome runners from all over the country who gather to
            take part in Little Bytham’s famous LB5K Farm Run. We usually
            organise this over the May Spring Bank Holiday, when many of the
            wildflowers are looking their best.
          </p>
          <p>
            Proceeds from the Farm Run go to support the Lincs Air Ambulance; an
            emergency service that is so vital to rural areas like ours and
            which relies entirely upon voluntary support.
          </p>
        </v-col>
      </v-row>
    </div>

    <!---show on small-->
    <div class="d-md-none">
      <v-row class="run-row">
        <v-col class="wild-col" cols="12" sm="12" md="6" lg="6" xl="6">
          <div style="display: inline-block">
            <h1>LB5K Farm Run</h1>
            <hr class="run-line" />
          </div>
          <p>
            Once a year, we give the permissive footpaths an extra special
            spruce up to welcome runners from all over the country who gather to
            take part in Little Bytham’s famous LB5K Farm Run. We usually
            organise this over the May Spring Bank Holiday, when many of the
            wildflowers are looking their best.
          </p>
          <p>
            Proceeds from the Farm Run go to support the Lincs Air Ambulance; an
            emergency service that is so vital to rural areas like ours and
            which relies entirely upon voluntary support.
          </p>
        </v-col>
        <v-col class="pa-0" cols="12" sm="12" md="6" lg="6" xl="6">
          <v-slide-x-reverse-transition>
            <div class="run-container" v-show="show">
              <div class="d-none d-md-block blocker">
                <img
                  src="../assets/svg/green-rec.svg"
                  class="run-triangle"
                  v-show="show"
                />
              </div>
              <div class="lb5k"></div>
            </div>
          </v-slide-x-reverse-transition>
        </v-col>
      </v-row>
    </div>
  </v-content>
</template>

<script>
export default {
  name: "WhatElse",
  metaInfo() {
    return {
      title: "Turners of Bytham | What Else?",
      meta: [
        {
          name: "description",
          content:
            "Turners of Bytham is operated as an open farm all year round, and is home to a number of communities, hobbyists and events, such as the Little Bytham LB5K Farm Run."
        },
        {
          property: "og:title",
          content: "Turners of Bytham | What Else?"
        },
        { property: "og:site_name", content: "Turners of Bytham" },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" }
      ]
    };
  },
  mounted() {
    this.show = true;
  },
  data() {
    return {
      show: false
    };
  }
};
</script>

<style scoped lang="scss">
hr.title-line {
  margin: 0;
  width: 100%;
  margin-top: -4.5%;
  margin-bottom: 10%;
  margin-left: 2.5px;
  border: 2px solid $underline-color;
  border-radius: 1px;
}

hr.wildlife-line {
  margin: 0;
  width: 100%;
  margin-top: -5%;
  margin-bottom: 17.5%;
  margin-left: 2.5px;
  border: 2px solid $underline-color;
  border-radius: 1px;
}

hr.run-line {
  margin: 0;
  width: 100%;
  margin-top: -5%;
  margin-bottom: 17.5%;
  margin-left: 2.5px;
  border: 2px solid $underline-color;
  border-radius: 1px;
}

.title-row {
  padding: 5%;
  padding-bottom: 0;
}

.text {
  padding: 5%;
  padding-top: 0;
}

.wild-col {
  padding: 5%;
  padding-top: 0;
}

.map-container {
  padding: 0;
  margin: 0;
}

.section {
  padding: 5% 0;
}

.run-row {
  padding: 5% 0;
}

.social {
  padding: 50px;
}

.we-banner {
  background-image: url("../assets/run-header.png");
  background-size: cover;
  min-width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  display: flex;
  justify-content: center;
}

.lb5k {
  background-image: url("../assets/run.jpg");
  height: 650px;
  width: 100%;
  background-size: cover;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  z-index: 2;
  position: relative;
}

.ramblers {
  background-image: url("../assets/ramblers.png");
  height: 650px;
  width: 100%;
  background-size: cover;
  max-height: 100%;
  margin-left: auto;
  display: flex;
  z-index: 2;
  position: relative;
}

.side-padding {
  padding-left: 5%;
  padding-right: 5%;
}

.triangle {
  z-index: 1;
  position: absolute;
  bottom: -25px;
  left: -25px;
  max-width: 50%;
  max-height: 50%;
}

.run-triangle {
  z-index: 1;
  position: absolute;
  bottom: -25px;
  right: -25px;
  max-width: 50%;
  max-height: 50%;
}

.run-container {
  position: relative;
  transition: transform 150ms ease-in-out;
  max-width: 100%;
  max-height: 100%;
  padding-top: 0;
}

.wild-container {
  position: relative;
  transition: transform 150ms ease-in-out;
  max-width: 100%;
  max-height: 100%;
  padding-top: 0;
  margin-bottom: 0;
  max-width: 1000px !important;
  margin-left: auto;
}

@media screen and (max-width: 375px) {
  .we-banner {
    height: 200px;
  }

  .ramblers {
    height: 300px !important;
  }

  .lb5k {
    height: 300px !important;
  }

  h1 {
    font-size: $title-size-s !important;
  }

  p {
    font-size: $paragraph-size-xs !important;
    text-align: justify;
  }

  .section {
    margin-top: 0;
  }

  iframe {
    max-height: 300px !important;
  }

  .run-row {
    padding: 0;
  }

  .wild-col {
    padding-top: 5%;
  }
}

@media screen and (min-width: 376px) and (max-width: 600px) {
  .ramblers {
    height: 350px !important;
  }

  .lb5k {
    height: 350px !important;
  }

  .we-banner {
    height: 200px;
  }

  h1 {
    font-size: $title-size-s !important;
  }

  p {
    font-size: $paragraph-size-s !important;
    text-align: justify;
  }

  .section {
    margin-top: 0;
  }

  iframe {
    max-height: 350px !important;
  }
  .run-row {
    padding: 0;
  }

  .wild-col {
    padding-top: 5%;
  }
}

@media screen and (min-width: 601px) and (max-width: 959px) {
  .we-banner {
    height: 300px;
  }

  .wild-col {
    padding-top: 5%;
  }

  .ramblers {
    height: 450px !important;
  }

  .lb5k {
    height: 450px !important;
  }

  iframe {
    height: 450px !important;
  }

  h1 {
    font-size: $title-size-m !important;
  }

  p {
    font-size: $paragraph-size-m !important;
    text-align: justify;
  }

  .run-row {
    padding: 0;
  }

  .section {
    margin-top: 0;
  }

  .map-container {
  }
}

@media screen and (min-width: 960px) and (max-width: 1263px) {
  .ramblers {
    height: 450px !important;
  }

  .lb5k {
    height: 450px !important;
  }

  iframe {
    height: 450px !important;
  }

  h1 {
    font-size: $title-size-m !important;
  }

  p {
    font-size: $paragraph-size-m !important;
    text-align: justify;
  }
}
</style>
