<template>
  <div class="content">
    <v-row class="title-row wheats-row">
      <h2 class="crop-name ">Wheats</h2>
    </v-row>
    <v-row class="title-row wheats-row">
      <hr class="crop-line" />
    </v-row>

    <div class="container">
      <button v-on:click="showNext">
        <i
          data-v-b63b354c=""
          aria-hidden="true"
          class="v-icon mdi mdi-chevron-right"
          style="font-size: 36px;"
        ></i>
      </button>
      <button v-on:click="showPrev">
        <i
          data-v-b63b354c=""
          aria-hidden="true"
          class="v-icon mdi mdi-chevron-left"
          style="font-size: 36px;"
        ></i>
      </button>
      <VueSlickCarousel v-bind="settings" ref="carousel">
        <div v-for="wheat in wheats" :key="wheat.name">
          <crop-card
            :name="wheat.name"
            :subtitle="wheat.subtitle"
            :blurb="wheat.blurb"
            :text="wheat.text"
            :img="wheat.img"
            :thumbnail="wheat.thumbnail"
          ></crop-card>
        </div>
      </VueSlickCarousel>
    </div>

    <!--    <vue-glide :type="carousel" :bound="true" :rewind="true" :peek="100">-->
    <!--      <vue-glide-slide v-for="wheat in wheats" :key="wheat.name">-->
    <!--    <crop-card-->
    <!--      :name="wheat.name"-->
    <!--      :subtitle="wheat.subtitle"-->
    <!--      :blurb="wheat.blurb"-->
    <!--      :text="wheat.text"-->
    <!--      :img="wheat.img"-->
    <!--      :thumbnail="wheat.thumbnail"-->
    <!--    ></crop-card>-->
    <!--      </vue-glide-slide>-->
    <!--      <template slot="control">-->
    <!--        <button data-glide-dir="<">prev</button>-->
    <!--        <button data-glide-dir=">">next</button>-->
    <!--      </template>-->

    <!--    </vue-glide>-->
  </div>
</template>

<script>
import CropCard from "../components/CropCard";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "CarouselRow",
  components: {
    VueSlickCarousel,
    CropCard
  },
  data() {
    return {
      settings: {
        dots: true,
        arrows: false,
        focusOnSelect: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 3,
        touchThreshold: 5,
        responsive: [
          {
            breakpoint: 650,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1
            }
          },

          {
            breakpoint: 960,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              initialSlide: 1
            }
          },
          {
            breakpoint: 1263,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              initialSlide: 1
            }
          }
        ]
      },
      wheats: [
        {
          name: "YQ",
          subtitle: "Population Wheat (Autumn Sown)",
          blurb: "A population comprising up to 180 different wheats",
          text:
            "One of the most diverse crops that we grow, YQ is diverse population of up to 180 different crosses. Representing a lifetime’s work by Professor Martin Wolfe, these populations will eventually evolve to become as unique as the farm they are gown upon.",
          thumbnail: "yq.png",
          img: "yq-l.png"
        },
        {
          name: "Oak Farm Population",
          subtitle: "Population Wheat (Autumn Sown)",
          blurb: "The latest generation of population wheats",
          text:
            "Developed by Dr Ed Dickin, these population wheats lend their name to his Lincolnshire family farm. They share YQ’s principle of resilience and vigour through a diverse population of crosses. Ed uses a combination of heritage and more developed wheats for these populations. ",
          thumbnail: "ofp.jpg",
          img: "ofp.jpg"
        },
        {
          name: "Cornovii",
          subtitle: "Heritage Mixture (Autumn Sown)",
          blurb: "A mix of 7 heritage wheat varieties",
          text:
            "Developed by Fred Price at Gothelney Farm, Cornovii is a simple but diverse mix of seven heritage varieties: Hen Gymru, Holdfast, Old Devon Blue Rough Chaff, Percivals Blue Cone, Red Lammas, Wilhelmina and Yeoman. The Cornovii were a Celtic tribe - a fitting name for this historic blend.",
          thumbnail: "cornovii-l.jpg",
          img: "cornovii-l.jpg"
        },
        {
          name: "Öland",
          subtitle: "Landrace Wheat (Spring Sown)",
          blurb: "An ancient breed of wheat, originating from the Baltic",
          text:
            "Ölands is an ancient grain and although lower yielding, it has a high protein content and a terrific taste. The name comes from the island off Sweden where it originated, and we are indebted to Oscar Harding at Duchess Farm and E5 Bakehouse for introducing us to it.",
          thumbnail: "oland.png",
          img: "oland-l.png"
        },
        {
          name: "Edgar",
          subtitle: "Single Wheat (Autumn Sown)",
          blurb: "A German “all-rounder” wheat with a great flavour.",
          text:
            "Single variety wheats still have a place alongside the more specialist wheats we grow. Edgar is renowned for its flavour and it has proved to be particularly versatile, giving good results in breads, pastries, biscuits and pasta.",
          thumbnail: "edgar.png",
          img: "edgar-l.png"
        }
      ]
    };
  },
  methods: {
    showNext() {
      this.$refs.carousel.next();
    },

    showPrev() {
      this.$refs.carousel.prev();
    }
  }
};
</script>

<style lang="scss" scoped>
.content {
  width: 80vw;
  margin: auto;
  margin-top: 5em;
}

h2.crop-name {
  padding: 0;
  margin: 0;
  padding-top: 0.5em;
}

.container {
  margin-top: 2em;
}

.mdi-chevron-right {
  color: #af9a6a;
  top: 50%;
  left: 100%;
  position: absolute;
  z-index: 50;
}

.mdi-chevron-right:active {
  color: #6e5e3a;
}

.mdi-chevron-left {
  color: #af9a6a;
  top: 50%;
  right: 100%;
  position: absolute;
  z-index: 50;
}

.mdi-chevron-left:active {
  color: #6e5e3a;
}

.v-window__next {
  top: 50%;
  right: 100%;
  position: absolute;
}

.prevbtn {
  top: 50%;
  left: 100%;
  position: absolute;
}

hr.crop-line {
  margin: 0;
  width: 4em;
  margin-bottom: 1.5em;
  border: 1px solid #f2ddaf;
}

.wheats-row {
  justify-content: center;
}

@media screen and (max-width: 375px) {
  .content {
    width: 80vw;
  }

  h1 {
    font-size: $title-size-s !important;
  }
}

@media screen and (min-width: 376px) and (max-width: 600px) {
  .content {
    width: 80vw;
  }

  h1 {
    font-size: $title-size-s !important;
  }
}

@media screen and (min-width: 601px) and (max-width: 959px) {
  h1 {
    font-size: $title-size-m !important;
  }
}

@media screen and (min-width: 960px) and (max-width: 1263px) {
  h1 {
    font-size: $title-size-m !important;
  }
}
</style>
