<template>
  <div class="content">
    <div style="display: inline-block; margin: 5% 0;">
      <a class="follow" href="https://www.instagram.com/turnersofbytham/"
        ><h1>Follow Us</h1></a
      >
      <hr class="follow-line" />
    </div>

    <div id="instagram-photos" class="instagram-photos"></div>
    <div class="isloading" v-show="loading">Loading Feed..</div>
  </div>
</template>

<script>
export default {
  name: "SocialFeed",
  data() {
    return {
      loading: false
    };
  }
};

const axios = require("axios");

const instagramRegExp = new RegExp(
  /<script type="text\/javascript">window\._sharedData = (.*);<\/script>/
);

const fetchInstagramPhotos = async accountUrl => {
  let response;
  try {
    response = await axios.get(accountUrl);
  } catch (e) {
    // console.error(e);
  }
  const json = JSON.parse(response.data.match(instagramRegExp)[1]);
  const edges = json.entry_data.ProfilePage[0].graphql.user.edge_owner_to_timeline_media.edges.splice(
    0,
    8
  );
  const photos = edges.map(({ node }) => {
    return {
      url: `https://www.instagram.com/p/${node.shortcode}/`,
      thumbnailUrl: node.thumbnail_src,
      displayUrl: node.display_url,
      caption:
        node.edge_media_to_caption.edges.length > 0
          ? node.edge_media_to_caption.edges[0].node.text
          : ""
    };
  });
  return photos;
};

let count = 0;

(async () => {
  try {
    const photos = await fetchInstagramPhotos(
      "https://www.instagram.com/turnersofbytham/"
    );
    const container = document.getElementById("instagram-photos");
    photos.forEach(el => {
      const content = document.createTextNode(photos[count].caption);
      const overlaytext = document.createElement("div");
      const overlay = document.createElement("div");
      const div = document.createElement("div");
      const a = document.createElement("a");
      const img = document.createElement("img");

      a.setAttribute("href", el.url);
      a.setAttribute("target", "_blank");
      a.setAttribute("rel", "noopener noreferrer");
      a.classList.add("instagram-photo");

      div.setAttribute("class", "photo-container");

      overlay.className = "overlay";

      overlaytext.setAttribute("class", "overlaytext");

      img.setAttribute("src", el.thumbnailUrl);
      img.setAttribute("alt", el.caption);

      a.appendChild(img);
      div.appendChild(img);
      overlaytext.appendChild(content);
      a.appendChild(overlay);
      overlay.appendChild(overlaytext);
      div.appendChild(a);
      container.appendChild(div);

      count += 1;
    });
  } catch (e) {
    // console.log(e);
  }
})();
</script>

<style scoped lang="scss">
.content {
  text-align: center;
}

h1 {
  line-height: 1.2;
  margin-bottom: 35px;
  text-align: center;
  font-size: $title-size-l;
}

hr.follow-line {
  margin: 0;
  width: 100%;
  margin-top: -25%;
  margin-bottom: 10%;
  margin-left: 2.5px;
  border: 2px solid $underline-color;
  border-radius: 1px;
}

.instagram-photos {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px 16px;
}

@media screen and (max-width: 375px) {
  h1 {
    font-size: $subtitle-size-s;
  }
}

@media screen and (min-width: 375px) and (max-width: 600px) {
  h1 {
    font-size: $title-size-s !important;
  }
}

@media screen and (min-width: 601px) and (max-width: 959px) {
  h1 {
    font-size: $title-size-m !important;
  }
}

@media screen and (min-width: 960px) and (max-width: 1263px) {
}
</style>
