<template>
  <div class="container">
    <div class="title">
      <h2>{{ name }}</h2>
      <hr class="crop-line" />
    </div>
    <VueSlickCarousel v-bind="settings" ref="carousel">
      <div v-for="crop in crops" :key="crop.name">
        <crop-card
          :name="crop.name"
          :subtitle="crop.subtitle"
          :blurb="crop.blurb"
          :text="crop.text"
          :img="crop.img"
          :thumbnail="crop.thumbnail"
        ></crop-card>
      </div>
    </VueSlickCarousel>
    <button v-show="crops.length > 1" v-on:click="showNext">
      <i
              data-v-b63b354c=""
              aria-hidden="true"
              class="v-icon mdi mdi-chevron-right"
              style="font-size: 36px;"
      ></i>
    </button>
    <button v-show="crops.length > 1" v-on:click="showPrev">
      <i
              data-v-b63b354c=""
              aria-hidden="true"
              class="v-icon mdi mdi-chevron-left"
              style="font-size: 36px;"
      ></i>
    </button>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import CropCard from "./CropCard";

export default {
  name: "CropCarousel",
  props: ["crops", "name"],
  components: {
    VueSlickCarousel,
    CropCard
  },
  data() {
    return {
      settings: {
        dots: true,
        arrows: false,
        focusOnSelect: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        touchThreshold: 5
      }
    };
  },
  methods: {
    showNext() {
      this.$refs.carousel.next();
    },

    showPrev() {
      this.$refs.carousel.prev();
    }
  }
};
</script>

<style scoped>
.title {
  display: grid;
  justify-content: center;
  margin-top: 1em;
}
.container {
  padding: 0;
}

.mdi-chevron-right {
  color: #af9a6a;
  top: 50%;
  left: 90%;
  position: absolute;
}

.mdi-chevron-right:active {
  color: #6e5e3a;
}

.mdi-chevron-left {
  color: #af9a6a;
  top: 50%;
  right: 90%;
  position: absolute;
}

.mdi-chevron-left:active {
  color: #6e5e3a;
}

hr.crop-line {
  width: 4rem;
  margin: auto;
  margin-bottom: 2em;
  margin-top: 0.5em;
  border: 1px solid #f2ddaf;
}

h1 {
}
</style>
