<template>
  <v-content>
    <v-slide-x-transition>
      <v-row justify="center" align="center" v-show="show">
        <div class="home-banner" align="center"></div>
      </v-row>
    </v-slide-x-transition>
    <v-row>
      <v-slide-y-transition>
        <div class="welcome-text" v-show="show">
          <div style="display: inline-block">
            <h1>Turners of Bytham</h1>
            <hr class="title-line" />
          </div>

          <p class="welcome-p">
            Every farm has a story to tell, as unique as the farm itself. For
            some, it is an important part of knowing where their food comes from
            and the wider values that they are supporting. For others, it is a
            chance to get to know the farmland whose trails they have enjoyed
            exploring. So, whatever your reason for searching us out, thank you
            and this is just a really small snapshot of what we do and the
            people who help to make it all happen.
          </p>
          <v-col class="quote-col">
            <v-row class="ma-0">
              <h2 class="quote">
                “Telling a story is like reaching into a granary full of wheat
                and drawing out a handful. There is always more to tell than can
                be told.”
              </h2>
            </v-row>
            <v-row class="ma-0">
              <h2 class="author">Wendell Berry</h2>
            </v-row>
          </v-col>

          <v-row align="center" class="hare-rule">
            <v-col class="rule-col">
              <hr class="left-rule" />
            </v-col>
            <v-col class="hare-col">
              <img
                src="../assets/svg/jumping-hare.svg"
                height="40px"
                width="70px"
                draggable="false"
              />
            </v-col>
            <v-col class="rule-col">
              <hr class="right-rule" />
            </v-col>
          </v-row>
        </div>
      </v-slide-y-transition>
    </v-row>

    <v-row class="interactive">
      <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="pa-0">
        <v-slide-x-transition>
          <v-col class="pa-0" v-show="show">
            <img src="../assets/farm.png" class="map" />
          </v-col>
        </v-slide-x-transition>
      </v-col>
      <v-col class="pa-0" cols="12" sm="12" md="6" lg="6" xl="6">
        <v-col class="middle-col">
          <v-slide-x-reverse-transition>
            <div class="right" v-show="show">
              <div style="display: inline-block">
                <h1 class="side-title">The Grange</h1>
                <hr class="grange-line" />
              </div>

              <p class="img-text">
                The Grange is a traditional, organic farm cared for by the
                Turners for over four generations. Tucked away in the small
                village of Little Bytham near Stamford, the farm is able to
                produce some very special crops from its small (by today’s
                standards) 100ha. It sits overlooking a broad valley
                formed by the once great Bytham River, whose legacy is its
                unique range of soils. John and Guy continue the same long
                tradition of truly mixed, rotational farming that has a passion
                for our produce.
              </p>
            </div>
          </v-slide-x-reverse-transition>
        </v-col>
      </v-col>
    </v-row>

    <v-row justify="center" align="start" class="passion-container">
      <v-slide-x-transition>
        <v-flex md6 v-show="show">
          <v-col class="group d-flex"></v-col>
        </v-flex>
      </v-slide-x-transition>
      <v-flex md6>
        <v-col class="passion-col">
          <v-slide-y-transition>
            <v-row
              class="white-col-spacer d-none d-md-block"
              v-show="show"
            ></v-row>
          </v-slide-y-transition>
          <v-row class="history-row">
            <v-row>
              <v-flex>
                <v-slide-y-transition>
                  <v-col class="history-col" v-show="show">
                    <h1 class="history">Our History</h1>
                    <p>
                      There are reminders that farming has been practiced in
                      Little Bytham over many centuries. One of the suggested
                      origins of the village’s name “Bytham” comes from the
                      Anglo-Saxon term "cultivated village”. In medieval times,
                      the land was sought by the Earl of Albemarle, half-brother
                      to William the Conqueror, “to give him some land which
                      would bear wheat, whereby he might be better able to
                      nourish his son”.
                    </p>
                  </v-col>
                </v-slide-y-transition>
              </v-flex>
            </v-row>
          </v-row>
        </v-col>
      </v-flex>
    </v-row>
    <v-row class="history-spacer d-none d-lg-block"></v-row>
  </v-content>
</template>

<script>
export default {
  name: "TheFarm",
  components: {  },
  metaInfo() {
    return {
      title: "Turners of Bytham | Little Bytham Organic Farming",
      meta: [
        {
          name: "description",
          content:
                  "Turners of Bytham, an organic pasture fed farm in Little Bytham, Lincolnshire. Built on principles of rotational organic farming and heritage crops."
        },
        {
          property: "og:title",
          content: "Turners of Bytham | Little Bytham Organic Farming"
        },
        { property: "og:site_name", content: "Turners of Bytham" },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" }
      ]
    };
  },
  mounted() {
    this.show = true;
  },
  data() {
    return {
      show: false
    };
  }
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Open+Sans");

.home-banner {
  height: 400px;
  background-image: url("../assets/home-banner-test.jpg");
  width: 100%;
  background-size: cover;
  background-position: top right;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title-text {
  color: #ffffff;
  font-size: $title-size-l;
}

.welcome-text {
  padding: 5%;
  padding-bottom: 0;
}

hr.title-line {
  margin: 0;
  width: 100%;
  margin-top: -5%;
  margin-bottom: 10%;
  margin-left: 2.5px;
  border: 2px solid $underline-color;
  border-radius: 1px;
}

hr.grange-line {
  margin: 0;
  width: 100%;
  margin-top: -7.5%;
  margin-bottom: 10%;
  margin-left: 2.5px;
  border: 2px solid $underline-color;
  border-radius: 1px;
}

.welcome-quote-col {
  padding: 8%;
}

p.welcome-p {
  font-size: $paragraph-size-l !important;
  text-align: justify;
}

ul {
  list-style-type: none;
  padding: 0 !important;
}

.skills {
  width: 90%;
  max-width: 90%;
  height: 600px;
  position: relative;
}

.middle-col {
  display: table;
  height: 100%;
  padding: 0;
}

.lines {
  height: 100%;
  position: relative;
}

.passion-container {
}

h1 {
  font-size: $title-size-l;
  font-family: "Monterchi-Book";
}

h1.banner-text {
  color: #ffffff;
  font-weight: 900;
  font-family: Monterchi-Book serif;
  font-size: 55px;
  text-align: center;
  padding: 0;
}

h2 {
  font-family: "Monterchi-Book", serif;
  font-weight: 100;
  font-size: 42px;
  text-align: left;
  color: #555555;
  padding-bottom: 0.5em;
}

h2.quote {
  color: rgba(190, 157, 103, 0.81);
  font-family: "Monterchi-Book", serif;
  font-weight: 100;
  font-size: $flair-size-m;
  text-align: center;
  display: block;
  padding-bottom: 0.5em !important;
  margin: auto;
}

h1.history {
  color: #555555;
  font-size: $title-size-l;
  padding-left: 0px;
}

h1.side-title {
}

h2.author {
  color: #555555;
  font-family: "Monterchi-Book", serif;
  font-weight: 100;
  font-size: $flair-size-m;
  text-align: center;
  display: block;
  padding-bottom: 0 !important;
  margin: auto;
}

p.img-text {
  text-align: left;
}

.hare-rule {
  margin-top: 2.5%;
}

hr.left-rule {
  margin-left: 25%;
  margin-right: 0;
  width: 75%;
  padding: 0;
}

hr.right-rule {
  margin-right: 25%;
  margin-left: 0;
  width: 75%;
  padding: 0;
}

.rule-col {
  max-height: 2px;
  padding-left: 15px;
  padding-right: 15px;
}

.hare-col {
  width: 70px !important;
  max-width: 70px !important;
  max-height: 42px !important;
  padding: 0;
}

.john-triangle {
  z-index: 1;
  position: absolute;
  bottom: -25px;
  left: -25px;
  max-width: 50%;
  max-height: 50%;
}

.group {
  background-image: url("../assets/group.png");
  height: $img-height-l;
  background-size: cover;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  padding: 0px;
  position: relative;
}

.container {
  padding-bottom: 0 !important;
}

.v-content {
  margin-bottom: 50px;
}

.quote-col {
  margin: 0;
  padding: 0;
  padding-top: 1em;
}

.farm-text {
  margin-top: 0em;
}

.map {
  height: 100%;
  width: 100%;
  border-right: 2px solid #696969;
  padding: 5%;
}

.history-section {
  padding: 5%;
}

.history-col {
  padding: 5%;
}

.history-spacer {
  background-color: #fcf6e9;
  height: 75px;
}

.history-row {
  background-color: #fcf6e9;
}

.right {
  text-align: left;
  padding-left: 5%;
  display: table-cell;
  vertical-align: middle;
}

.farm-img {
  display: inline;
}

.interactive {
  padding: 5%;
}

@media screen and (max-width: 375px) {
  .group {
    height: $img-height-xs !important;
  }

  .right {
    padding: 0;
  }

  h1.grange {
    font-size: $title-size-xs !important;
    margin: 0px !important;
  }

  h2.quote {
    font-size: $flair-size-xs;
  }

  h2.author {
    font-size: $flair-size-xs;
  }

  .welcome-text {
    margin: 5% 0;
    padding-left: 5% !important;
    padding-right: 5% !important;
  }

  p.welcome-p {
    font-size: $paragraph-size-s !important;
    padding: 0px !important;
    margin: 0px !important;
  }

  .home-banner {
    height: 200px;
  }

  h1.history {
    font-size: $subtitle-size-s !important;
  }

  div.history-col {
    padding: 10%;
  }

  h1.side-title {
    font-size: $subtitle-size-s !important;
    margin: 0px !important;
  }
  p.img-text {
    font-size: $paragraph-size-s !important;
    text-align: justify;
  }
}

@media screen and (min-width: 376px) and (max-width: 600px) {
  h1 {
    font-size: $title-size-s !important;
  }

  .right {
    padding: 0;
  }

  .home-banner {
    height: 200px;
  }

  h2.quote {
    font-size: $paragraph-size-m;
  }

  h2.author {
    font-size: $paragraph-size-m;
  }

  .welcome-text {
    padding-left: 5% !important;
    padding-right: 5% !important;
  }
  h1.banner-text {
    font-size: $title-size-m !important;
  }

  p {
    font-size: $paragraph-size-s !important;
  }

  p.welcome-p {
    font-size: $paragraph-size-s !important;
  }

  .history-section {
    padding: 5% !important;
    padding-left: 10% !important;
    padding-right: 10% !important;
  }

  .charts {
    padding-top: 5%;
    padding-right: 5%;
  }

  h1.farm {
    padding-bottom: 0 !important;
  }

  .icons {
    margin-left: 0 !important;
  }

  .map {
    border-right: none;
  }

  .chart {
    width: 100%;
  }

  .group {
    height: $img-height-s;
  }

  h1.history {
    font-size: $subtitle-size-s !important;
  }
  div.history-col {
    display: table-cell;
    vertical-align: middle;
    padding: 9%;
  }

  h1.side-title {
    font-size: $subtitle-size-s !important;
  }
  p.img-text {
    font-size: $paragraph-size-s !important;
    text-align: justify;
  }
}

@media screen and (min-width: 601px) and (max-width: 959px) {
  .welcome-text {
    margin: 5% 0;
  }

  h1 {
    font-size: $title-size-m !important;
  }

  h2.quote {
    font-size: $flair-size-s;
  }

  h2.author {
    font-size: $flair-size-s;
  }

  p.welcome-p {
    font-size: $paragraph-size-m;
  }

  .welcome-quote-col {
    padding: 3% 8% 5% 8%;
  }

  .history-section {
    padding: 5% !important;
    padding-left: 10% !important;
    padding-right: 10% !important;
  }

  .home-banner {
    height: 300px;
  }

  .map {
    border-right: none;
    max-height: 750px;
    max-width: 750px;
    margin: 0 auto;
  }

  .charts {
    padding-top: 5%;
  }

  .skills {
    width: 100% !important;
    max-width: 100% !important;
  }

  .icons {
    margin-left: 0 !important;
  }

  .icon-img {
    width: 40px;
    margin-left: 0 !important;
  }

  .bar-col {
    padding-left: 10%;
  }

  .chart {
    width: 80%;
  }

  .group {
    height: $img-height-m;
  }

  .right {
    padding: 0;
  }

  h1.history {
    font-size: $subtitle-size-m !important;
  }

  div.history-col {
    padding: 10%;
    padding-left: 8%;
  }

  h1.side-title {
    font-size: $subtitle-size-m !important;
  }
  p.img-text {
    font-size: $paragraph-size-m !important;
    text-align: justify;
  }
}

@media screen and (min-width: 960px) and (max-width: 1263px) {
  h2.quote {
    font-size: $flair-size-m;
  }

  h2.author {
    font-size: $flair-size-m;
  }

  p {
    font-size: $paragraph-size-l;
  }

  .group {
    height: $img-height-m;
  }

  .history-col {
    padding: 8%;
  }
}

@media screen and (min-width: 1264px) {
  .history-col {
    padding: 8%;
  }
}
</style>
