<template>
  <div>
    <v-slide-x-transition>
      <v-card
        max-width="200"
        max-height="400"
        min-height="400"
        v-on:click.stop="dialog = true"
        v-show="show"
      >
        <v-img
          height="250"
          v-bind:src="getImgUrl(thumbnail)"
          class="image"
        ></v-img>
        <v-card-title>{{ name }}</v-card-title>
        <v-card-subtitle>{{ subtitle }}</v-card-subtitle>
        <v-card-text>
          <div>
            {{ blurb }}
          </div>
        </v-card-text>
      </v-card>
    </v-slide-x-transition>

    <v-dialog
      v-model="dialog"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      width="90%"
    >
      <div class="container">
        <div class="column">
          <template v-if="window.width > 1024">
            <p class="X" v-on:click.stop="dialog = false">x</p>
          </template>
          <div class="text-container">
            <div class="title-container">
              <h1 class="name">{{ name }}</h1>
            </div>
            <div class="grain-info">
              <h3 class="sub">{{ subtitle }}</h3>
            </div>
            <p class="para">
              {{ text }}
            </p>
          </div>
        </div>
        <div class="column">
          <template v-if="window.width <= 1024">
            <p class="dark-X" v-on:click.stop="dialog = false">x</p>
          </template>
          <v-img height="100%" v-bind:src="getImgUrl(img)"></v-img>
        </div>
      </div>

      <!--      <v-row class="dialog-row">-->
      <!--        <v-col class="pa-0">-->
      <!--          <div class="text-container">-->
      <!--            <div class="title-container">-->
      <!--              <h1 class="name">{{ name }}</h1>-->
      <!--            </div>-->
      <!--            <div class="grain-info">-->
      <!--              <h3 class="sub">{{ subtitle }}</h3>-->
      <!--            </div>-->
      <!--            <p class="para">-->
      <!--              {{ text }}-->
      <!--            </p>-->
      <!--          </div>-->
      <!--        </v-col>-->
      <!--        <v-col class="pa-0">-->
      <!--          <v-img height="100%" v-bind:src="getImgUrl(img)"></v-img>-->
      <!--        </v-col>-->
      <!--      </v-row>-->
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "CropCard",
  props: ["name", "subtitle", "blurb", "text", "thumbnail", "img"],

  data() {
    return {
      show: false,
      window: {
        width: 0,
        height: 0
      },
      dialog: false
    };
  },
  mounted() {
    this.show = true;
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    getImgUrl(pic) {
      return require("../assets/crops/" + pic);
    },

    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    }
  }
};
</script>

<style lang="scss" scoped>
.v-card__title {
  font-size: $paragraph-size-m !important;
  color: #3e3e3e;
  font-weight: 500;
  padding: 4px 16px !important;
}

.v-card__subtitle {
  font-size: $paragraph-size-xs !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.v-card {
  border-radius: 2px !important;
  text-align: left;
  margin: auto;
}

h1.name {
  margin: 0;
  padding: 0;
}

h3.sub {
  margin-bottom: 0.5em;
}

.container {
  padding: 0;
  height: 600px;
  background-color: white;
}

.container:after {
  content: "";
  display: table;
  clear: both;
}

.column {
  float: left;
  width: 50%;
  height: 100%;
  background-color: white;
}

.X {
  text-align: right;
  margin: 0;
  position: absolute;
  top: 0;
  right: 2%;
  color: #ffffff;
  font-weight: 900;
  z-index: 20;
  cursor: pointer;
  font-size: 32px;
}
.dark-X {
  text-align: right;
  margin: 0;
  position: absolute;
  top: 0;
  right: 1%;
  color: #555555;
  font-weight: 900;
  z-index: 20;
  cursor: pointer;
  font-size: 32px;
}

.text-container {
  padding: 3em;
}

@media screen and (max-width: 1024px) {
  .container {
    height: 80vh !important;
  }

  .column {
    height: 40vh !important;
    width: 100%;
    overflow: hidden;
  }
}

@media screen and (max-width: 375px) {
  .title-container {
    margin: 0;
  }

  .text-container {
    padding: 2em;
  }

  h1.name {
    font-size: $title-size-xs !important;
  }

  h3.sub {
    font-size: $flair-size-xs;
  }

  p.para {
    font-size: $paragraph-size-xs !important;
    text-align: justify;
  }

  .container {
    height: 100vh !important;
  }

  .column {
    height: 50vh !important;
    width: 100%;
    overflow: hidden;
  }

  .text-container {
    top: 25%;
    position: relative;
    padding-top: 0;
  }
}

@media screen and (min-width: 376px) and (max-width: 600px) {
  h1.name {
    font-size: $title-size-s !important;
  }

  h3.sub {
    font-size: $flair-size-s;
  }

  p.para {
    font-size: $paragraph-size-s !important;
    text-align: justify;
  }

  p.dark-X {
    font-size: $title-size-xs !important;
  }

  .container {
    height: 100vh !important;
  }

  .column {
    height: 50vh !important;
    width: 100%;
    overflow: hidden;
  }

  .text-container {
    top: 25%;
    position: relative;
    padding-top: 0;
  }
}

@media screen and (min-width: 601px) and (max-width: 959px) {
  .container {
    height: 80vh !important;
  }

  .column {
    height: 40vh !important;
    width: 100%;
    overflow: hidden;
  }

  h1.name {
    font-size: $title-size-m !important;
  }

  h3.sub {
    font-size: $flair-size-m;
  }

  p.para {
    font-size: $paragraph-size-m !important;
    text-align: justify;
  }
}

@media screen and (min-width: 960px) and (max-width: 1263px) {
}

@media screen and (max-width: 1024px) {
}
</style>
