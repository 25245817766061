import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import LoadScript from "vue-plugin-load-script";
import axios from "axios";
import VueMeta from "vue-meta";

Vue.use(LoadScript);

Vue.use(VueMeta);

Vue.prototype.$http = axios;

Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount("#app");
