<template>
  <v-content>
    <v-slide-x-transition>
      <v-row v-show="show">
        <div class="banner"></div>
      </v-row>
    </v-slide-x-transition>

    <v-slide-y-transition>
      <div class="text-container" v-show="show">
        <v-slide-y-transition>
          <v-row class="content-row">
            <div style="display: inline-block">
              <h1>We Grow...</h1>
              <hr class="title-line" />
            </div>
          </v-row>
        </v-slide-y-transition>

        <v-slide-y-transition>
          <v-row class="text-row">
            <p>
              The farm produces a diverse range of crops, most of which are
              destined for a local mill or bakery. We also occasionally still
              produce a few crops for malting and these go into more specialised
              bulk markets. Between the cereal crops, a lot of the farm’s fields
              grow grass to rest them and help replenish the fertility. Other
              areas of the farm are managed purely to get the best environment
              for wildlife, where we have a particular focus on bumblebees,
              butterflies, day moths, and of course our Hares are such an iconic
              feature of the farm. These are just some of the crops we grow and
              a little bit about what makes each one special.
            </p>
          </v-row>
        </v-slide-y-transition>

        <v-row align="center" class="p-hare-rule">
          <v-col class="p-rule-col">
            <hr class="p-left-rule" />
          </v-col>
          <v-col class="hare-col">
            <img
              src="../assets/svg/jumping-hare.svg"
              height="40px"
              width="70px"
              draggable="false"
            />
          </v-col>
          <v-col class="p-rule-col">
            <hr class="p-right-rule" />
          </v-col>
        </v-row>

        <v-row class="content-row">
          <div style="display: inline-block">
            <h1 class="crops-title">Our Crops</h1>
            <hr class="title-line" />
          </div>
        </v-row>
        <v-row class="crops-row">
          <v-slide-y-transition>
            <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="pa-0">
              <div class="crops-section">
                <p>
                  The crops we grow reflect the organic system practiced on the
                  farm and all of the land that can be either ploughed or
                  otherwise cultivated forms part of a seven-year rotation:
                </p>
                <div class="year-row">
                  <v-row>
                    <v-col cols="6" sm="6" md="3" lg="3" xl="3">
                      <v-row justify="center" class="year-icon">
                        <img
                          src="../assets/svg/barley.svg"
                          class="plant-img"
                          height="60"
                          width="60"
                        />
                      </v-row>
                      <v-row justify="center">
                        <p class="icon-text-s">
                          Year 1
                        </p>
                      </v-row>
                      <v-row justify="center">
                        <v-col class="year-div">
                          <hr class="year-line" />
                        </v-col>
                      </v-row>
                      <v-row justify="center">
                        <p class="icon-text">
                          Wheat
                        </p>
                      </v-row>
                    </v-col>
                    <v-col cols="6" sm="6" md="3" lg="3" xl="3">
                      <v-row justify="center" class="year-icon">
                        <img
                          src="../assets/svg/oats.svg"
                          class="plant-img"
                          height="60"
                          width="60"
                        />
                      </v-row>
                      <v-row justify="center">
                        <p class="icon-text-s">
                          Year 2
                        </p>
                      </v-row>
                      <v-row justify="center">
                        <v-col class="year-div">
                          <hr class="year-line" />
                        </v-col>
                      </v-row>
                      <v-row justify="center">
                        <p class="icon-text">
                          Oats
                        </p>
                      </v-row>
                    </v-col>
                    <v-col cols="6" sm="6" md="3" lg="3" xl="3">
                      <v-row justify="center" class="year-icon">
                        <img
                          src="../assets/svg/peas.svg"
                          class="plant-img"
                          height="60"
                          width="60"
                        />
                      </v-row>
                      <v-row justify="center" >
                        <p class="icon-text-s">
                          Year 3
                        </p>
                      </v-row>
                      <v-row justify="center">
                        <v-col class="year-div">
                          <hr class="year-line" />
                        </v-col>
                      </v-row>
                      <v-row justify="center">
                        <p class="icon-text">
                          Barley or Peas
                        </p>
                      </v-row>
                    </v-col>
                    <v-col cols="6" sm="6" md="3" lg="3" xl="3">
                      <v-row justify="center" class="year-icon">
                        <img
                          src="../assets/svg/grass.svg"
                          class="plant-img"
                          height="60"
                          width="60"
                        />
                      </v-row>
                      <v-row justify="center" >
                        <p class="icon-text-s">
                          Years 4-7
                        </p>
                      </v-row>
                      <v-row justify="center">
                        <v-col class="year-div">
                          <hr class="year-line" />
                        </v-col>
                      </v-row>
                      <v-row justify="center">
                        <p class="icon-text">
                          Grass & Herbs
                        </p>
                      </v-row>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </v-col>
          </v-slide-y-transition>
          <v-col class="bar-col" cols="12" sm="12" md="6" lg="6" xl="6">
            <div class="skills">
              <div class="charts">
                <v-row>
                  <v-col class="icons pa-0">
                    <ul>
                      <li class="icon-img">
                        <v-slide-y-transition>
                          <img
                            src="../assets/svg/leaf.svg"
                            class="plant-img"
                            height="40px"
                            width="50px"
                          />
                        </v-slide-y-transition>
                      </li>
                      <li class="icon-img">
                        <v-slide-y-transition>
                          <img
                            src="../assets/svg/oats.svg"
                            class="plant-img"
                            height="40px"
                            width="50px"
                          />
                        </v-slide-y-transition>
                      </li>
                      <li class="icon-img">
                        <v-slide-y-transition>
                          <img
                            src="../assets/svg/barley.svg"
                            class="plant-img"
                            height="50px"
                            width="60px"
                          />
                        </v-slide-y-transition>
                      </li>
                      <li class="icon-img">
                        <v-slide-y-transition>
                          <img
                            src="../assets/svg/grass.svg"
                            class="plant-img"
                            height="50px"
                            width="60px"
                          />
                        </v-slide-y-transition>
                      </li>
                    </ul>
                  </v-col>
                  <v-col class="pa-0">
                    <div class="chart chart--dev">
                      <ul class="chart--horiz">
                        <v-slide-y-transition>
                          <div class="label-container">
                            <span class="label">Environmental</span>
                          </div>
                        </v-slide-y-transition>
                        <li class="peas__bar" style="width: 46%;">
                          <span class="bar-tag">12 ha.</span>
                        </li>
                        <v-slide-y-transition>
                          <div class="label-container">
                            <span class="label">Oats</span>
                          </div>
                        </v-slide-y-transition>
                        <li class="cereals__bar" style="width: 47.5%;">
                          <span class="bar-tag">12.5 ha.</span>
                        </li>
                        <v-slide-y-transition>
                          <div class="label-container">
                            <span class="label">Wheat</span>
                          </div>
                        </v-slide-y-transition>
                        <li class="wheat__bar" style="width: 66%;">
                          <span class="bar-tag">22 ha.</span>
                        </li>
                        <v-slide-y-transition>
                          <div class="label-container">
                            <span class="label">Pastoral</span>
                          </div>
                        </v-slide-y-transition>
                        <li class="pastoral__bar" style="width: 100%;">
                          <span class="bar-tag">58 ha.</span>
                        </li>
                      </ul>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-col>
        </v-row>

        <CarouselRow></CarouselRow>

        <v-row class="crop-container">
          <v-col class="crop-col">
            <CropCarousel :crops="oats" name="Oats"></CropCarousel>
          </v-col>
          <v-col class="crop-col">
            <CropCarousel :crops="peas" name="Peas"></CropCarousel>
          </v-col>
          <v-col class="crop-col">
            <CropCarousel :crops="barleys" name="Barleys"></CropCarousel>
          </v-col>
        </v-row>
      </div>
    </v-slide-y-transition>
  </v-content>
</template>

<script>
import CarouselRow from "../components/CarouselRow";
import CropCarousel from "../components/CropCarousel";

export default {
  name: "WeGrow",
  components: { CropCarousel, CarouselRow },
  metaInfo() {
    return {
      title: "Turners of Bytham | We Grow",
      meta: [
        {
          name: "description",
          content:
            "Turners of Bytham produces a diverse range of crops, most of which are destined for a local mill or bakery. Explore everything on the farm here!"
        },
        {
          property: "og:title",
          content: "Turners of Bytham | We Grow"
        },
        { property: "og:site_name", content: "Turners of Bytham" },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" }
      ]
    };
  },
  mounted() {
    this.show = true;
  },
  data() {
    return {
      show: false,
      barleys: [
        {
          name: "Laureatte",
          subtitle: "Malting Barley (Spring Sown)",
          blurb: "A modern malting Barley with a distinct flavour.",
          text:
            "Principally used for brewing and distilling, malted barleys can also be used as a cooking ingredient.  Barley grows well as a crop on our limestone soils and sits alongside Oats as a good crop to follow wheat in the rotation.",
          thumbnail: "laureatte.png",
          img: "laureatte.png"
        }
      ],
      wheats: [
        {
          name: "YQ",
          subtitle: "Population Wheat (Autumn Sown)",
          blurb: "A population comprising up to 180 different wheats",
          text:
            "One of the most diverse crops that we grow, YQ is diverse population of up to 180 different crosses. Representing a lifetime’s work by Professor Martin Wolfe, these populations will eventually evolve to become as unique as the farm they are gown upon.",
          thumbnail: "yq.png",
          img: "yq-l.png"
        },
        {
          name: "Oak Farm Population",
          subtitle: "Population Wheat (Autumn Sown)",
          blurb: "The latest generation of population wheats",
          text:
            "Developed by Dr Ed Dickin, these population wheats lend their name to his Lincolnshire family farm. They share YQ’s principle of resilience and vigour through a diverse population of crosses. Ed uses a combination of heritage and more developed wheats for these populations. ",
          thumbnail: "ofp.jpg",
          img: "ofp.jpg"
        },
        {
          name: "Cornovii",
          subtitle: "Heritage Mixture (Autumn Sown)",
          blurb: "A mix of 7 heritage wheat varieties",
          text:
            "Developed by Fred Price at Gothelney Farm, Cornovii is a simple but diverse mix of seven heritage varieties: Hen Gymru, Holdfast, Old Devon Blue Rough Chaff, Percivals Blue Cone, Red Lammas, Wilhelmina and Yeoman. The Cornovii were a Celtic tribe - a fitting name for this historic blend.",
          thumbnail: "cornovii-l.jpg",
          img: "cornovii-l.jpg"
        },
        {
          name: "Öland",
          subtitle: "Landrace Wheat (Spring Sown)",
          blurb: "An ancient breed of wheat, originating from the Baltic",
          text:
            "Ölands is an ancient grain and although lower yielding, it has a high protein content and a terrific taste. The name comes from the island off Sweden where it originated, and we are indebted to Oscar Harding at Duchess Farm and E5 Bakehouse for introducing us to it.",
          thumbnail: "oland.png",
          img: "oland-l.png"
        },
        {
          name: "Edgar",
          subtitle: "Single Wheat (Autumn Sown)",
          blurb: "A German “all-rounder” wheat with a great flavour.",
          text:
            "Single variety wheats still have a place alongside the more specialist wheats we grow. Edgar is renowned for its flavour and it has proved to be particularly versatile, giving good results in breads, pastries, biscuits and pasta.",
          thumbnail: "edgar.png",
          img: "edgar-l.png"
        }
      ],
      oats: [
        {
          name: "Mascani",
          subtitle: "Husked Oat (Autumn Sown)",
          blurb: "A modern variety, widely used for porridge oats and rolling.",
          text:
            "Mascani is one of the staple varieties that is used to produce Porridge Oats, Muesli together with a wide range of other foods and drinks. They are a versatile food but need to be processed to remove the inedible husk before use.",
          thumbnail: "mascani-l.jpg",
          img: "mascani-l.jpg"
        },
        {
          name: "Oliver",
          subtitle: "Naked Oat (Spring Sown)",
          blurb:
            "Originating from China, “Naked” oats benefit from having no husk.",
          text:
            "Unlike husked varieties, Naked Oats need no specialised processing before they can be used. With an appearance similar to rice and an incredible potential as a cooking ingredient, they are a crop we believe has a huge untapped potential.",
          thumbnail: "oliver-l.jpg",
          img: "oliver-l.jpg"
        }
      ],
      peas: [
        {
          name: "Kabuki",
          subtitle: "Marrowfat Pea (Spring Sown)",
          blurb: "A Marrowfat variety, harvested as a dried pea.",
          text:
            "We often grow these Marrowfat peas under our organic system as part of Hodmedod’s fantastic efforts to develop British grown protein crops.  They are an exciting and versatile ingredient that deserve to be recognised as being so much more than simply a “Mushy Pea” ",
          thumbnail: "kabuki.jpg",
          img: "kabuki-l.jpg"
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
@mixin stagger-anim-delay($i, $initial-delay, $stagger-delay) {
  @while $i > 0 {
    &:nth-of-type(#{$i}) {
      animation-delay: $initial-delay + $stagger-delay * $i;
    }
    $i: $i - 1;
  }
}

.year-div {
  padding-top: 6px !important;
}

.p-rule-col {
  max-height: 2px;
  padding-left: 20px;
  padding-right: 20px;
}

hr.p-left-rule {
  margin-left: 25% !important;
  width: 75%;
  padding: 0;
  margin: 0;
}

hr.p-right-rule {
  margin-right: 25% !important;
  width: 75%;
  padding: 0;
  margin: 0;
}

hr.title-line {
  margin: 0;
  width: 100%;
  margin-top: -5%;
  margin-bottom: 17.5%;
  margin-left: 2.5px;
  border: 2px solid $underline-color;
  border-radius: 1px;
}

h1.history {
  padding: 0;
}

.year-icon {
  padding-bottom: 10px;
}

.year-line {
  width: 25%;
  margin: 0 37.5%;
  color: #f2ddaf;
}

.crop-col {
  max-width: 30%;
  min-width: 300px;
  margin: auto;
  margin-top: 1em;
}

.p-hare-rule {
  margin-top: 5%;
  margin-bottom: 0;
}

.bar-col {
  padding: 0;
}

.v-btn {
  color: #f2ddaf !important;
}

.card-row {
  margin: 5%;
  justify-content: center;
}

.icon-text-s {
  font-size: $paragraph-size-s;
  margin-bottom: 0;
  margin-top: 1rem;
  font-weight: 500;
}

.icon-text {
  margin: 0;
  padding-left: 4px;
  padding-top: 0 !important;
  text-align: center;
}

.year-row {
  color: #555555;
  background: #fcf6e9;
  padding: 5%;
  margin-top: 5%;
}

.crop-container {
  width: 80vw;
  margin: auto;
  margin-bottom: 2em;
  margin-top: 4em;
}

.year {
  margin-bottom: 2em;
}

.card-col {
  max-width: 400px;
  min-width: 300px !important;
  margin: 0 10px;
}

.card-container {
  justify-content: center;
  padding-top: 5%;
}

.content-row {
  padding: 5%;
  padding-bottom: 0;
}

.crops-row {
  padding: 0 5%;
}

.crops-section {
  padding-bottom: 5%;
}

.title-row {
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 5%;
  justify-content: center;
}

.history-section {
  padding-left: 0;
}

.text-row {
  padding: 0 5%;
}

.banner {
  background-image: url("../assets/group-grow.jpg");
  background-size: cover;
  min-width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  display: flex;
  justify-content: center;
}

.plant-img {
  margin: 0;
  pointer-events: none;
  width: 60px !important;
}

.icons {
  margin-left: 15%;
  max-width: 10%;
  padding-right: 0;
}

.icon-img {
  margin-top: 1.5em;
  margin-bottom: 55px;
  margin-right: 0.5em;
  text-align: right;
  vertical-align: middle;
}

.icon-img {
  width: 60px;
  height: 50px;
}

.chart {
  margin-left: 1em !important;
}

.skills {
  height: 400px;
}

h1.crop-name {
  padding: 0;
  margin: 0;
  padding-top: 0.5em;
}

hr.crop-line {
  margin: 0;
  width: 4rem;
  margin-top: 1%;
  margin-bottom: 1%;
  border: 1px solid #f2ddaf;
}

.bar-tag {
  text-align: right;
  width: inherit;
  color: #fdfddf;
  font-weight: 600;
  line-height: 35px;
  margin-right: 0.5em;
}

.line {
  height: inherit;
  width: 2px;

  position: absolute;

  background: rgba(#eee, 0.6);

  &.l--0 {
    left: 0;
  }
  &.l--25 {
    left: 25%;
  }
  &.l--50 {
    left: 50%;
  }
  &.l--75 {
    left: 75%;
  }
  &.l--100 {
    left: calc(100% - 1px);
  }
}

.charts {
  width: 100%;
  height: 400px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.chart {
  margin: 30px 0 0;

  &:first-child {
    margin: 0;
  }
}

.chart--horiz {
  overflow: hidden;
  padding-left: 0 !important;
}

.wheat__bar {
  height: 35px;
  margin-bottom: 40px;
  border-radius: 2px;
  text-align: right;

  //background: linear-gradient(to left, #bf6d26, #d48d50);
  background: #e1a645 url("../assets/wheat.png");
  opacity: 0;

  animation: 0.6s anim-lightspeed-in ease forwards;

  .chart--dev & {
    @include stagger-anim-delay(11, 0.2s, 0.2s);
  }
  .chart--prod & {
    @include stagger-anim-delay(2, 3.8s, 0.2s);
  }
  .chart--design & {
    @include stagger-anim-delay(3, 5s, 0.2s);
  }
}

.cereals__bar {
  height: 35px;
  margin-bottom: 40px;
  border-radius: 2px;
  text-align: right;

  //background: linear-gradient(to left, #bf6d26, #d48d50);
  background: url("../assets/oats.png");
  opacity: 0;

  animation: 0.6s anim-lightspeed-in ease forwards;

  .chart--dev & {
    @include stagger-anim-delay(11, 0.2s, 0.2s);
  }
  .chart--prod & {
    @include stagger-anim-delay(2, 3.8s, 0.2s);
  }
  .chart--design & {
    @include stagger-anim-delay(3, 5s, 0.2s);
  }
}

.peas__bar {
  height: 35px;
  margin-bottom: 40px;
  border-radius: 2px;
  text-align: right;

  //background: linear-gradient(to left, #bf6d26, #d48d50);
  background: #11460e url("../assets/environmental.png");
  opacity: 0;

  animation: 0.6s anim-lightspeed-in ease forwards;

  .chart--dev & {
    @include stagger-anim-delay(11, 0.2s, 0.2s);
  }
  .chart--prod & {
    @include stagger-anim-delay(2, 3.8s, 0.2s);
  }
  .chart--design & {
    @include stagger-anim-delay(3, 5s, 0.2s);
  }
}

.pastoral__bar {
  height: 35px;
  margin-bottom: 40px;
  border-radius: 2px;
  text-align: right;

  //background: linear-gradient(to left, #bf6d26, #d48d50);
  background: url("../assets/pastoral.png");
  opacity: 0;

  animation: 0.6s anim-lightspeed-in ease forwards;

  .chart--dev & {
    @include stagger-anim-delay(11, 0.2s, 0.2s);
  }
  .chart--prod & {
    @include stagger-anim-delay(2, 3.8s, 0.2s);
  }
  .chart--design & {
    @include stagger-anim-delay(3, 5s, 0.2s);
  }
}

.label {
  line-height: 30px;
  color: #555555;
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
  animation: 1s anim-lightspeed-in ease forwards;
}

//Keyframes
@keyframes anim-lightspeed-in {
  0% {
    transform: translateX(-200%);
    opacity: 1;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@media screen and (max-width: 375px) {
  h1 {
    font-size: $title-size-s !important;
  }

  p {
    font-size: $paragraph-size-xs !important;
    text-align: justify;
  }

  span.label {
    font-size: $paragraph-size-s !important;
  }

  .plant-img {
    height: 45px;
    width: 45px;
  }

  .year-row {
    margin: 10%;
    margin-bottom: 0;
  }

  .banner {
    height: 200px;
  }

  .chart {
    padding-left: 6px;
  }

  .crops-section {
    padding-bottom: 10%;
  }

  .history-section {
    padding-bottom: 0;
  }

  .skills {
    width: 95% !important;
    max-width: 95% !important;
  }
}

@media screen and (min-width: 376px) and (max-width: 600px) {
  .banner {
    height: 200px;
  }

  .skills {
    width: 95% !important;
    max-width: 95% !important;
  }

  .bar-col {
    padding-left: 10%;
  }

  .year-row {
    margin: 5%;
    margin-bottom: 0;
  }

  .plant-img {
    height: 50px;
    width: 50px;
  }

  h1 {
    font-size: $title-size-s !important;
  }

  p {
    font-size: $paragraph-size-s !important;
    text-align: justify;
  }

  span.label {
    font-size: $paragraph-size-s !important;
  }

  .chart {
    padding-left: 24px;
  }

  .history-section {
    padding-bottom: 0;
  }

  .skills {
    width: 95% !important;
    max-width: 95% !important;
  }
}

@media screen and (min-width: 601px) and (max-width: 959px) {
  .banner {
    height: 300px;
  }

  .plant-img {
    height: 55px;
    width: 55px;
  }

  .year-row {
    margin: 10%;
    margin-bottom: 0;
    padding: 2.5% 5%;
  }

  .year-icon {
    padding-top: 1em;
  }

  h1 {
    font-size: $title-size-m !important;
  }

  p {
    font-size: $paragraph-size-m !important;
    text-align: justify;
  }

  span.label {
    font-size: $paragraph-size-m !important;
  }
}

@media screen and (min-width: 960px) and (max-width: 1263px) {
  h1 {
    font-size: $title-size-m !important;
  }

  p {
    font-size: $paragraph-size-m !important;
    text-align: justify;
  }
}
</style>
