<template>
  <v-content>
    <v-slide-x-transition>
      <v-row v-show="show">
        <div class="partners-banner"></div>
      </v-row>
    </v-slide-x-transition>

    <v-slide-y-transition>
      <v-row class="text" v-show="show">
        <v-col class="pa-0">
          <v-row class="ma-0">
            <v-col class="pa-0">
              <div style="display: inline-block">
                <h1 class="partners">Our Partners</h1>
                <hr class="underline" />
              </div>

              <p class="partners-text">
                We are lucky to work with some very special businesses that
                enable us to do the things we do; providing the inspiration for
                us to grow new crops and find new ways to get the best from them
                once they leave the farm. So much of what we grow, from the “YQ”
                population wheats to the Naked Oats, have each come from a
                conversation started over a cup of tea at Tuxford’s windmill or
                Small Food’s café that begins: “How do we make this work?”
              </p>
              <v-row class="ma-0">
                <h2 class="quote">
                  “A proper community…., is a commonwealth: a place, a resource,
                  an economy. It answers the needs, practical as well as social
                  and spiritual, of its members - among them the need to need
                  one another.”
                </h2>
              </v-row>
              <v-row class="text-padding">
                <h2 class="author">Wendell Berry</h2>
              </v-row>
            </v-col>
          </v-row>
          <v-row align="center" class="p-hare-rule">
            <v-col class="p-rule-col">
              <hr class="p-left-rule" />
            </v-col>
            <v-col class="hare-col">
              <img
                src="../assets/svg/jumping-hare.svg"
                height="40px"
                width="70px"
                draggable="false"
              />
            </v-col>
            <v-col class="p-rule-col">
              <hr class="p-right-rule" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-slide-y-transition>

    <!--Desktop-->
    <div class="d-none d-md-block">
      <v-row justify="center" align="center" class="pic-right">
        <v-slide-y-transition>
          <v-col
            class="partner-info"
            v-show="show"
            cols="12"
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <div class="title-container">
              <h2>The Small Food Bakery, Nottingham</h2>
              <h3 class="link">
                <a
                  href="http://www.smallfoodbakery.com/"
                  class="sfb-link"
                  target="_blank"
                  >smallfoodbakery.com</a
                >
              </h3>
            </div>
            <hr class="title-line" />

            <p class="left-text">
              Kim and her team bring the magic that transforms the wheat we grow
              into the stunning loaves and pastries for which they are renowned.
              The partnership between our farm, Tuxford Windmill and The Small
              Food Bakery is so much more than a simple, local food chain.
              Through sharing our experiences of the crops, how they mill and
              how they bake, we quickly realised that conventional approaches to
              what constitutes a “good” milling wheat are simply a reflection of
              the constraints imposed by a particular industrial process.
              Artisan bakeries like Kim’s have no such constraints, being able
              to adapt their techniques batch-by batch to get the best out of
              what the grain can offer.
            </p>
          </v-col>
        </v-slide-y-transition>
        <v-slide-x-reverse-transition>
          <v-col class="pa-0" cols="12" sm="12" md="6" lg="6" xl="6">
            <div class="bakery-container" v-show="show">
              <div class="d-none d-md-block blocker">
                <img
                  src="../assets/svg/bright-blue-rec.svg"
                  class="left-triangle"
                  v-show="show"
                />
              </div>
              <div class="bakery"></div>
            </div>
          </v-col>
        </v-slide-x-reverse-transition>
      </v-row>

      <v-row justify="center" align="center" class="pic-left">
        <v-col class="pa-0" cols="12" sm="12" md="6" lg="6" xl="6">
          <v-slide-x-reverse-transition>
            <div class="bakery-container" v-show="show">
              <div class="d-none d-md-block blocker">
                <img
                  src="../assets/svg/dark-triangle-left.svg"
                  class="right-triangle"
                  v-show="show"
                />
              </div>
              <div class="mill"></div>
            </div>
          </v-slide-x-reverse-transition>
        </v-col>
        <v-slide-y-transition>
          <v-col
            class="right-text"
            v-show="show"
            cols="12"
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <div class="title-container">
              <h2>Tuxford Windmill</h2>
              <h3 class="link">
                <a
                  href="http://www.tuxford-windmill.co.uk/"
                  class="tuxford-link"
                  target="_blank"
                  >tuxford-windmill.co.uk</a
                >
              </h3>
            </div>
            <hr class="title-line" />

            <p id="fari">
              Paul and Fari acquired their four-sail windmill in 2005, following
              its restoration by the Ostick family. They quickly moved this
              beautiful structure from museum-only condition to fully
              functioning windmill. Over the years they have developed the range
              of flours that they produce and throughout have remained committed
              to the principles of “Local First” both in sourcing their wheats
              and in the markets that they have nurtured. More recently, they
              have been joined by apprentice miller Nicki, who will ensure those
              unique skills and experience are carried forward to the next
              generation of millers. Many of the decisions that we make on the
              farm about the varieties we choose and the way in which we grow
              them are both informed and inspired by the small hive of industry
              that is Tuxford Mill.
            </p>
          </v-col>
        </v-slide-y-transition>
      </v-row>

      <v-row justify="center" align="center" class="pic-right">
        <v-slide-y-transition>
          <v-col
            class="partner-info"
            v-show="show"
            cols="12"
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <div class="title-container">
              <h2>Hodmedod</h2>
              <h3 class="link">
                <a
                  href="https://hodmedods.co.uk/"
                  class="hodmedods-link"
                  target="_blank"
                  >hodmedods.co.uk</a
                >
              </h3>
            </div>
            <hr class="title-line" />

            <p class="left-text">
              Nick, Josiah and William have helped to lead an astonishing
              rediscovery of British grains and pulses. Starting from their
              involvement in a study to explore ways in which a city like
              Norwich could be more self-sufficient in food production, Hodmedod
              is a wonderful example of what can be achieved given the necessary
              determination and belief to turn a vision into a reality.
            </p>

            <p class="left-text">
              Alongside tried and trusted crops like Fava Beans, Hodmedod are
              supporting the development of a diverse range of food crops with a
              clear focus on their nutritional value. For many of these, turning
              them into a viable supply chain still depends upon developing
              varieties suitable to our climate or developing specialist
              machinery for harvesting or processing. For others, it is simply a
              matter of waiting for the markets to develop sufficiently to make
              them a commercially viable option.
            </p>
            <p class="left-text">
              We are fortunate to work with the Hodmedod team in growing
              certified organic “Kabuki” Marrowfat Peas.
            </p>
          </v-col>
        </v-slide-y-transition>
        <v-col class="pa-0" cols="12" sm="12" md="6" lg="6" xl="6">
          <v-slide-x-reverse-transition>
            <div class="mill-container" v-show="show">
              <div class="d-none d-md-block blocker">
                <img
                  src="../assets/svg/green-triangle-right.svg"
                  class="left-triangle"
                  v-show="show"
                />
              </div>
              <div class="hodmedod"></div>
            </div>
          </v-slide-x-reverse-transition>
        </v-col>
      </v-row>

      <v-row justify="center" align="center" class="pic-left">
        <v-col class="pa-0" cols="12" sm="12" md="6" lg="6" xl="6">
          <v-slide-x-reverse-transition>
            <div class="bakery-container" v-show="show">
              <div class="d-none d-md-block blocker">
                <img
                  src="../assets/svg/yellow-triangle.svg"
                  class="right-triangle"
                  v-show="show"
                />
              </div>
              <div class="e5"></div>
            </div>
          </v-slide-x-reverse-transition>
        </v-col>
        <v-slide-y-transition>
          <v-col
            class="right-text"
            v-show="show"
            cols="12"
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <div class="title-container">
              <h2 class="test">E5 Bakehouse</h2>
              <h3 class="link">
                <a
                  href="https://e5bakehouse.com/"
                  class="e5-link"
                  target="_blank"
                  >e5bakehouse.com</a
                >
              </h3>
            </div>
            <hr class="title-line" />
            <p>
              Like The Small Food Bakery, the E5 Bakehouse is so much more than
              simply an artisan bakery. It is a direct link between the crops we
              grow and the those that support our way of farming through their
              choice of the flours, breads and pastries. Pioneers like Ben
              Mackinnon share with us a common determination to make simple,
              localised food systems not only work but flourish. They have in
              turn inspired others to follow and the growth in independent
              artisan bakeries in London in recent times is a joy to see.
            </p>
          </v-col>
        </v-slide-y-transition>
      </v-row>
    </div>

    <!---show on small-->
    <div class="d-md-none">
      <v-row justify="center" align="center" class="pic-right">
        <v-slide-x-reverse-transition>
          <v-col class="pa-0" cols="12" sm="12" md="6" lg="6" xl="6">
            <div class="bakery-container" v-show="show">
              <div class="d-none d-md-block blocker">
                <img
                  src="../assets/svg/bright-blue-rec.svg"
                  class="left-triangle"
                  v-show="show"
                />
              </div>
              <div class="bakery"></div>
            </div>
          </v-col>
        </v-slide-x-reverse-transition>

        <v-slide-y-transition>
          <v-col
            class="partner-info"
            v-show="show"
            cols="12"
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <div class="title-container">
              <h2>The Small Food Bakery, Nottingham</h2>
              <h3 class="link">
                <a
                  href="http://www.smallfoodbakery.com/"
                  class="sfb-link"
                  target="_blank"
                  >smallfoodbakery.com</a
                >
              </h3>
            </div>
            <hr class="title-line" />

            <p class="left-text">
              Kim and her team bring the magic that transforms the wheat we grow
              into the stunning loaves and pastries for which they are renowned.
              The partnership between our farm, Tuxford Windmill and The Small
              Food Bakery is so much more than a simple, local food chain.
              Through sharing our experiences of the crops, how they mill and
              how they bake, we quickly realised that conventional approaches to
              what constitutes a “good” milling wheat are simply a reflection of
              the constraints imposed by a particular industrial process.
              Artisan bakeries like Kim’s have no such constraints, being able
              to adapt their techniques batch-by batch to get the best out of
              what the grain can offer.
            </p>
          </v-col>
        </v-slide-y-transition>
      </v-row>

      <v-row justify="center" align="center" class="pic-left">
        <v-col class="pa-0" cols="12" sm="12" md="6" lg="6" xl="6">
          <v-slide-x-reverse-transition>
            <div class="bakery-container" v-show="show">
              <div class="d-none d-md-block blocker">
                <img
                  src="../assets/svg/dark-triangle-left.svg"
                  class="right-triangle"
                  v-show="show"
                />
              </div>
              <div class="mill"></div>
            </div>
          </v-slide-x-reverse-transition>
        </v-col>
        <v-slide-y-transition>
          <v-col
            class="right-text"
            v-show="show"
            cols="12"
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <div class="title-container">
              <h2>Tuxford Windmill</h2>
              <h3 class="link">
                <a
                  href="http://www.tuxford-windmill.co.uk/"
                  class="tuxford-link"
                  target="_blank"
                  >tuxford-windmill.co.uk</a
                >
              </h3>
            </div>
            <hr class="title-line" />

            <p id="fari">
              Paul and Fari acquired their four-sail windmill in 2005, following
              its restoration by the Ostick family. They quickly moved this
              beautiful structure from museum-only condition to fully
              functioning windmill. Over the years they have developed the range
              of flours that they produce and throughout have remained committed
              to the principles of “Local First” both in sourcing their wheats
              and in the markets that they have nurtured. More recently, they
              have been joined by apprentice miller Nicki, who will ensure those
              unique skills and experience are carried forward to the next
              generation of millers. Many of the decisions that we make on the
              farm about the varieties we choose and the way in which we grow
              them are both informed and inspired by the small hive of industry
              that is Tuxford Mill.
            </p>
          </v-col>
        </v-slide-y-transition>
      </v-row>

      <v-row justify="center" align="center" class="pic-right">
        <v-col class="pa-0" cols="12" sm="12" md="6" lg="6" xl="6">
          <v-slide-x-reverse-transition>
            <div class="mill-container" v-show="show">
              <div class="d-none d-md-block blocker">
                <img
                  src="../assets/svg/green-triangle-right.svg"
                  class="left-triangle"
                  v-show="show"
                />
              </div>
              <div class="hodmedod"></div>
            </div>
          </v-slide-x-reverse-transition>
        </v-col>
        <v-slide-y-transition>
          <v-col
            class="partner-info"
            v-show="show"
            cols="12"
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <div class="title-container">
              <h2>Hodmedod</h2>
              <h3 class="link">
                <a
                  href="https://hodmedods.co.uk/"
                  class="hodmedods-link"
                  target="_blank"
                  >hodmedods.co.uk</a
                >
              </h3>
            </div>
            <hr class="title-line" />

            <p class="left-text">
              Nick, Josiah and William have helped to lead an astonishing
              rediscovery of British grains and pulses. Starting from their
              involvement in a study to explore ways in which a city like
              Norwich could be more self-sufficient in food production, Hodmedod
              is a wonderful example of what can be achieved given the necessary
              determination and belief to turn a vision into a reality.
            </p>

            <p class="left-text">
              Alongside tried and trusted crops like Fava Beans, Hodmedod are
              supporting the development of a diverse range of food crops with a
              clear focus on their nutritional value. For many of these, turning
              them into a viable supply chain still depends upon developing
              varieties suitable to our climate or developing specialist
              machinery for harvesting or processing. For others, it is simply a
              matter of waiting for the markets to develop sufficiently to make
              them a commercially viable option.
            </p>
            <p class="left-text">
              We are fortunate to work with the Hodmedod team in growing
              certified organic “Kabuki” Marrowfat Peas.
            </p>
          </v-col>
        </v-slide-y-transition>
      </v-row>

      <v-row justify="center" align="center" class="pic-left">
        <v-col class="pa-0" cols="12" sm="12" md="6" lg="6" xl="6">
          <v-slide-x-reverse-transition>
            <div class="bakery-container" v-show="show">
              <div class="d-none d-md-block blocker">
                <img
                  src="../assets/svg/yellow-triangle.svg"
                  class="right-triangle"
                  v-show="show"
                />
              </div>
              <div class="e5"></div>
            </div>
          </v-slide-x-reverse-transition>
        </v-col>
        <v-slide-y-transition>
          <v-col
            class="right-text"
            v-show="show"
            cols="12"
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <div class="title-container">
              <h2 class="test">E5 Bakehouse</h2>
              <h3 class="link">
                <a
                  href="https://e5bakehouse.com/"
                  class="e5-link"
                  target="_blank"
                  >e5bakehouse.com</a
                >
              </h3>
            </div>
            <hr class="title-line" />
            <p>
              Like The Small Food Bakery, the E5 Bakehouse is so much more than
              simply an artisan bakery. It is a direct link between the crops we
              grow and the those that support our way of farming through their
              choice of the flours, breads and pastries. Pioneers like Ben
              Mackinnon share with us a common determination to make simple,
              localised food systems not only work but flourish. They have in
              turn inspired others to follow and the growth in independent
              artisan bakeries in London in recent times is a joy to see.
            </p>
          </v-col>
        </v-slide-y-transition>
      </v-row>
    </div>
  </v-content>
</template>

<script>
export default {
  name: "OurPartners",
  metaInfo() {
    return {
      title: "Turners of Bytham | Our Partners",
      meta: [
        {
          name: "description",
          content:
            "Browse a range of partners, who work to create such a short and easily followed supply chain; all the way from peas, wheats or oats  in Little Bytham to loaves, cakes, and meals across the country.\n"
        },
        {
          property: "og:title",
          content: "Turners of Bytham | Our Partners"
        },
        { property: "og:site_name", content: "Turners of Bytham" },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" }
      ]
    };
  },
  mounted() {
    this.show = true;
  },
  data() {
    return {
      show: false
    };
  }
};
</script>

<style scoped lang="scss">
h1.partners {
  position: relative;
  z-index: 2;
}

h2 {
  padding-bottom: 0%;
  line-height: 1;
}

h2.quote {
  padding: 1em 0em;
}

.partners-text {
  margin-bottom: 2.5%;
  text-align: justify;
}

h3.link {
  font-size: 18px;
}

hr {
  margin-top: 2%;
  margin-bottom: 2%;
  width: 50%;
  margin-left: 25%;
  margin-right: 25%;
}

hr.title-line {
  margin: 0;
  width: 25%;
  margin-top: 5%;
  margin-bottom: 5%;
  border: 1px solid #be9d67;
}

hr.underline {
  margin: 0;
  width: 100%;
  margin-top: -5%;
  margin-bottom: 15%;
  margin-left: 2px;
  border: 2px solid $underline-color;
  border-radius: 1px;
  position: relative;
  z-index: 1;
}

.partners-banner {
  background-image: url("../assets/blue.jpg");
  background-size: cover;
  min-width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  display: flex;
  justify-content: center;
}

.p-rule-col {
  max-height: 2px;
  padding-left: 20px;
  padding-right: 20px;
}

.title-container {
  margin-bottom: 0;
}

hr.p-left-rule {
  margin-left: 25% !important;
  width: 75%;
  padding: 0;
  margin: 0;
}

.p-hare-rule {
  margin-top: 2.5%;
  margin-bottom: 0;
}

hr.p-right-rule {
  margin-right: 25% !important;
  width: 75%;
  padding: 0;
  margin: 0;
}

.mill {
  background-image: url("../assets/mill.jpg");
  height: $img-height-l;
  width: 100%;
  background-size: cover;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  z-index: 2;
  position: relative;
}

.hodmedod {
  background-image: url("../assets/hodmedod-team.jpg");
  height: $img-height-l;
  width: 100%;
  background-size: cover;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  z-index: 2;
  position: relative;
}

.mill-container {
  position: relative;
  transition: transform 150ms ease-in-out;
  max-width: 100%;
  max-height: 100%;
  padding-top: 0;
  margin-bottom: 25px;
}

.blocker {
  z-index: 1;
  display: table-cell;
}

.partner-info {
  padding: 5%;
  padding-left: 0 !important;
}

.left-triangle {
  z-index: 1;
  position: absolute;
  bottom: -25px;
  left: -25px;
  max-width: 50%;
  max-height: 50%;
}

.right-triangle {
  z-index: 1;
  position: absolute;
  bottom: -25px;
  right: -25px;
  max-width: 50%;
  max-height: 50%;
}

h3.link {
  margin-top: -0.25em;
}

.sfb-link {
  color: #75c0e1 !important;
}

.tuxford-link {
  color: rgba(85, 106, 192, 0.69) !important;
}

.hodmedods-link {
  color: #8ab88a !important;
}

.e5-link {
  color: #b6ac83 !important;
}

.bakery {
  background-image: url("../assets/bakery.png");
  height: $img-height-l;
  width: 100%;
  background-size: cover;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  z-index: 2;
  position: relative;
}

.e5 {
  background-image: url("../assets/e5.png");
  height: $img-height-l;
  width: 100%;
  background-size: cover;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  z-index: 2;
  position: relative;
}

.pic-right {
  margin: 5%;
  margin-top: 0;
  margin-right: 0;
}

.pic-left {
  margin: 5%;
  margin-left: 0;
}

.bakery-container {
  position: relative;
  transition: transform 150ms ease-in-out;
  max-width: 100%;
  max-height: 100%;
  padding-top: 0;
  margin-bottom: 25px;
}

.left-text {
  padding-right: 5%;
  text-align: justify;
  text-justify: inter-word;
}
.right-text {
  padding-left: 5%;
  text-align: justify;
  text-justify: inter-word;
}

.text {
  padding: 5%;
}

@media screen and (max-width: 375px) {
  .partners-banner {
    height: 200px;
  }

  .partners-text {
    margin-bottom: 2.5%;
    text-align: justify;
  }
  h1 {
    font-size: $title-size-xs !important;
  }

  h1.banner-text {
    font-size: $title-size-m !important;
  }

  h2 {
    font-size: $header-size-s !important;
  }

  h2.quote {
    font-size: $flair-size-xs !important;
  }

  h3.author {
    font-size: $header-size-xs !important;
  }

  p {
    font-size: $paragraph-size-s !important;
  }

  .partner-info {
    margin-left: 0;
  }

  .pic-right {
    margin: 0;
  }

  .e5 {
    height: $img-height-xs;
  }

  .bakery {
    height: $img-height-xs;
  }

  .mill {
    height: $img-height-xs;
  }

  .hodmedod {
    height: $img-height-xs;
  }

  .pic-left {
    margin: 0;
  }

  .partner-info {
    padding: 5% !important;
  }

  .right-text {
    padding: 10%;
  }

  .mill-container {
    margin-bottom: 0;
  }

  .left-text {
    padding: 0;
    margin-left: 0px !important;
  }
  #fari {
    margin-left: 0px !important;
  }

  .bakery-container {
    margin-bottom: 0 !important;
  }

  .text {
    padding: 10%;
  }
}

@media screen and (min-width: 376px) and (max-width: 600px) {
  .partners-banner {
    height: 200px;
  }

  .partners-text {
    margin-bottom: 2.5%;
    text-align: justify;
  }
  h1 {
    font-size: $title-size-xs !important;
  }

  h1.banner-text {
    font-size: $title-size-m !important;
  }

  h2 {
    font-size: $header-size-m !important;
  }

  h2.quote {
    font-size: $flair-size-xs !important;
  }

  h3.author {
    font-size: $header-size-xs !important;
  }

  p {
    font-size: $paragraph-size-s !important;
  }

  .bakery-container {
    margin-bottom: 0 !important;
  }

  .pic-right {
    margin: 0;
  }

  .pic-left {
    margin: 0;
  }

  .partner-info {
    padding: 5% !important;
    margin-left: 0 !important;
  }

  .text {
    padding: 5%;
  }

  .right-text {
    padding: 5%;
  }

  .mill-container {
    margin-bottom: 0;
  }

  .left-text {
    padding: 0;
  }

  .e5 {
    height: $img-height-s;
  }

  .bakery {
    height: $img-height-s;
  }

  .bakery-container {
    margin-bottom: 0 !important;
  }

  .mill {
    height: $img-height-s;
  }

  .hodmedod {
    height: $img-height-s;
  }
}

@media screen and (min-width: 601px) and (max-width: 959px) {
  .partners-banner {
    height: 300px;
  }

  .pic-right {
    margin: 0;
  }

  .pic-left {
    margin: 0;
  }

  .right-text {
    padding: 5%;
  }

  .mill-container {
    margin-bottom: 0;
  }

  .left-text {
    padding: 0;
  }

  .e5 {
    height: $img-height-m;
  }

  .bakery {
    height: $img-height-m;
  }

  .mill {
    height: $img-height-m;
  }

  .hodmedod {
    height: $img-height-m;
  }

  .partner-info {
    padding: 5% !important;
    margin-left: 0 !important;
  }

  .text {
    padding: 5%;
  }

  .bakery-container {
    margin-bottom: 0 !important;
  }
}

@media screen and (min-width: 960px) and (max-width: 1263px) {
  .e5 {
    height: $img-height-m;
  }

  .bakery {
    height: $img-height-m;
  }

  .mill {
    height: $img-height-m;
  }

  .hodmedod {
    height: $img-height-m;
  }
}
</style>
